import Search from "antd/es/input/Search";
import axios from "axios";
import { RefObject, useEffect, useRef, useState } from "react";
import {
  UserOutlined,
  CodeOutlined,
  SyncOutlined,
  MonitorOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Slider } from "./components/Slider";
import { SearchBar } from "./components/SearchBar";

import { ConfigProvider, theme, Button, Card, Divider, Spin } from "antd";
import { SideSegment } from "./components/SideSegment";
import { Chat } from "./components/Chat";
import { Insights } from "./components/Insights";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IConnectionProps } from "../database/Database";
import { baseUrl } from "../database/NewConnection";
import { useKeycloak } from "@react-keycloak/web";
import { IUserFeedbackProps } from "./components/Feedback";
import ChatV2 from "./components/Chatv2";

export interface IQuestionAnswerPropsV2 {
  id?: number;
  questionId?: string;
  user?: string;
  question?: string;
  type?: string;
  answer?: any;
  workflow_response?: string;
  graphs?: any;
  assumptions?: string;
  query?: string;
  sqlQueryExplanation?: string;
  data?: any;
  isLoading?: boolean;
  selectedTab?: string;
  clarificationData?: any;
  finalConfirmationData?: any;
  feedback?: IUserFeedbackProps;
  chartSelection?: string;
  graphFetching?: boolean;
  summaryFetching?: boolean;
}

const Interact = () => {
  const { chatId, connectionConfigId } = useParams();
  const navigate = useNavigate();
  const controllerRef = useRef(new AbortController());
  const [showChat, setShowChat] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState({});
  const [tab, setTab] = useState("0");
  const [chatList, setChatList] = useState<any>([]);
  const [questionAnswerListV2, setQuestionAnswerListV2] = useState<
    Array<IQuestionAnswerPropsV2>
  >([]);
  const [isChatListLoading, setIsChatListLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isNewChat, setIsNewChat] = useState(false);
  const [sampleQuestionList, setSampleQuestionList] = useState([]);
  const [fetchSummary, setFetchSummary] = useState<boolean>(false);
  const [fetchGraph, setFetchGraph] = useState<boolean>(false);

  const updateTab = (value: Array<IQuestionAnswerPropsV2>) => {
    setTab("0");
    setQuestionAnswerListV2(value);
  };
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const [isConnectionListLoading, setIsConnectionListLoading] = useState(true);
  const [connectionList, setConnectionList] = useState<Array<IConnectionProps>>(
    []
  );
  const [next, setNext] = useState(null);
  const [totalChatListLength, setTotalChatListLength] = useState(0);
  const [FollowUpQuestions, setFollowUpQuestions] = useState<Array<string>>([]);

  const { keycloak } = useKeycloak();

  useEffect(() => {
    axios
      .get(`${baseUrl}/connection/all/`)
      .then(function (response) {
        setConnectionList(response.data);
        if (response.data.length > 0 && !chatId && !connectionConfigId) {
          navigate("/interact/" + response.data[0].id);
        }
        setIsConnectionListLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionListLoading(false);
      });
  }, []);

  useEffect(() => {
    if (fetchSummary) {
      var tempQuestionAnswerListV2 = [...questionAnswerListV2];
      var ques = questionAnswerListV2.filter((row) => row.summaryFetching)[0];
      axios
        .get(
          `${baseUrl}/chat/v3/${connectionConfigId}/question/${ques.questionId}/summary/`
        )
        .then((response) => {
          console.log("called");
          ques.answer = response.data.response;
          ques.summaryFetching = false;
          console.log(tempQuestionAnswerListV2);
          setQuestionAnswerListV2(tempQuestionAnswerListV2);
        })
        .catch((error) => {
          ques.summaryFetching = false;
          console.log(error);
        });
      setFetchSummary(false);
    }
  }, [fetchSummary]);

  useEffect(() => {
    if (fetchGraph) {
      var tempQuestionAnswerListV2 = [...questionAnswerListV2];
      var ques = questionAnswerListV2.filter((row) => row.graphFetching)[0];
      axios
        .get(
          `${baseUrl}/chat/v3/${connectionConfigId}/question/${ques.questionId}/graph/`
        )
        .then((response) => {
          console.log("called", response.data);
          var graph_ = JSON.parse(response.data.response.graph_config);
          graph_.config = graph_.chart_config;
          delete graph_.chart_config;
          ques.graphs = ques.graphs ? ques.graphs : [];
          ques.graphs.push(graph_);
          ques.graphFetching = false;
          setQuestionAnswerListV2(tempQuestionAnswerListV2);
        })
        .catch((error) => {
          console.log(error);
        });
      setFetchGraph(false);
    }
  }, [fetchGraph]);

  useEffect(() => {
    if (connectionConfigId) {
      setIsChatListLoading(true);
      setQuestionAnswerListV2([]);
      axios
        .get(`${baseUrl}/chat/v3/${connectionConfigId}/all/?items_per_page=15`)
        .then(function (response) {
          setTotalChatListLength(response.data.count);
          setNext(response.data.next);
          setChatList(response.data.results);
          setSampleQuestionList(response.data.sample_question_list);
          setIsChatListLoading(false);
        });
    }
  }, [connectionConfigId]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [isLoading]);

  useEffect(() => {
    setFollowUpQuestions([]);
    console.log(chatId);
    if (chatId && !isNewChat) {
      setIsChatLoading(true);
      axios
        .get(`${baseUrl}/chat/v3/${connectionConfigId}/chat-history/${chatId}/`)
        .then(function (response) {
          setIsChatLoading(false);
          var responseData: Array<any> = response.data;
          setQuestionAnswerListV2(
            responseData.map((row, i) => {
              setFollowUpQuestions([]);
              var questionAnswerV2: IQuestionAnswerPropsV2 = {};
              questionAnswerV2.questionId = row.id;
              questionAnswerV2.user = row.user;
              questionAnswerV2.feedback = row.feedback;
              questionAnswerV2.question = row.question;
              questionAnswerV2.type = row.type;
              if (row.type === "completion") {
                console.log(row.response);
                questionAnswerV2.answer = row.response;
              } else if (row.type === "clarification") {
                questionAnswerV2.clarificationData = JSON.parse(row.response);
                if (i !== responseData.length - 1) {
                  console.log(responseData.length - 1, i);
                  questionAnswerV2.clarificationData.options = [];
                }
              } else if (row.type === "final_confirmation") {
                questionAnswerV2.finalConfirmationData = JSON.parse(
                  row.response
                );
                if (i !== responseData.length - 1) {
                  questionAnswerV2.finalConfirmationData.confirmed = true;
                }
              } else if (row.type === "query") {
                questionAnswerV2.query = row.response.sql;
                questionAnswerV2.sqlQueryExplanation = row.response.explanation;
                questionAnswerV2.data = JSON.parse(row.response.data);
                if (questionAnswerV2.data && questionAnswerV2.data.length > 0) {
                  questionAnswerV2.answer = row.response.summary
                    ? row.response.summary
                    : undefined;
                } else {
                  questionAnswerV2.answer = `<span>We don't have data available. Please try something else.</span>
                    <span>There are three possibilities:</span>
                    <ol style="margin: 0px">
                        <li>You might be looking for something which is stored slightly differently in the database.</li>
                        <li>Database don't have the data you are looking for.</li>
                        <li>The output of your query is NULL.</li>
                    </ol>`;
                }
                if (row.response.follow_up_questions) {
                  console.log(row.response.follow_up_questions);
                  setFollowUpQuestions(row.response.follow_up_questions);
                }
                if (row.response.graph) {
                  if (Array.isArray(row.response.graph)) {
                    var graphs = [];

                    for (var i = 0; i < row.response.graph.length; i++) {
                      var graph = JSON.parse(row.response.graph[i]);
                      if (graph.chart_config) {
                        graph.config = graph.chart_config;
                        delete graph.chart_config;
                      }
                      graph.is_editing = false;
                      graphs.push(graph);
                    }
                    questionAnswerV2.graphs = graphs;
                  } else {
                    var graph = JSON.parse(row.response.graph);
                    graph.config = graph.chart_config;
                    delete graph.chart_config;
                    questionAnswerV2.graphs = [graph];
                  }
                }
              }
              questionAnswerV2.isLoading = false;
              questionAnswerV2.summaryFetching = false;
              questionAnswerV2.graphFetching = false;
              questionAnswerV2.selectedTab = "2";
              return questionAnswerV2;
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setIsNewChat(false);
  }, [chatId]);

  const callExampleQuestion = (exampleQuestion: string) => {
    getData(exampleQuestion);
  };

  useEffect(() => {
    console.log(FollowUpQuestions);
  }, [FollowUpQuestions]);

  const getFollowUpQuestions = (agentId: string) => {
    if (chatId && connectionConfigId) {
      axios
        .post(
          `${baseUrl}/chat/v3/${connectionConfigId}/${chatId}/get-followup-questions/`,
          {
            id: agentId,
          }
        )
        .then((response: any) => {
          setFollowUpQuestions(response.data);
          console.log("FOLLOW UP", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getData = (question: string) => {
    setFollowUpQuestions([]);
    setIsLoading(true);
    var idV2 = questionAnswerListV2.length + 1;
    var tempQuestionAnswerListV2 = [...questionAnswerListV2];
    if (idV2 > 1) {
      if (tempQuestionAnswerListV2[idV2 - 2].type === "clarification") {
        tempQuestionAnswerListV2[idV2 - 2].clarificationData.options = [];
      } else if (
        tempQuestionAnswerListV2[idV2 - 2].type === "final_confirmation"
      ) {
        tempQuestionAnswerListV2[idV2 - 2].finalConfirmationData.confirmed =
          true;
      }
    }
    tempQuestionAnswerListV2.push({
      id: idV2,
      user: keycloak.idTokenParsed?.preferred_username,
      question: question,
      data: [],
      isLoading: true,
      selectedTab: "2",
    });
    questionAnswerListV2.push({
      id: idV2,
      user: keycloak.idTokenParsed?.preferred_username,
      question: question,
      data: [],
      isLoading: true,
      selectedTab: "2",
    });
    setQuestionAnswerListV2(tempQuestionAnswerListV2);
    getQuery(idV2, question);
  };

  const getQuery = (id: number, ques: string) => {
    // var question = inputVal;
    // setInputVal("");
    // setQuestion(question);
    setShowChat(true);
    const controller = new AbortController();
    controllerRef.current.abort(); // Cancel previous request
    controllerRef.current = controller; // Update the current AbortController
    axios
      .post(
        `${baseUrl}/chat/v3/${connectionConfigId}/question/`,
        {
          input: ques,
          chatId: chatId,
        },
        {
          signal: controller.signal,
          timeout: 300000,
        }
      )
      .then(function (response) {
        var tempQuestionAnswerListV2 = [...questionAnswerListV2];
        var questionAnswerV2 = tempQuestionAnswerListV2.filter(
          (row) => row.id === id
        )[0];
        questionAnswerV2.questionId = response.data.id;
        questionAnswerV2.user = response.data.user;
        questionAnswerV2.type = response.data.type;
        if (response.data.type === "completion") {
          questionAnswerV2.answer = response.data.response;
        } else if (response.data.type === "clarification") {
          questionAnswerV2.clarificationData = JSON.parse(
            response.data.response
          );
        } else if (response.data.type === "final_confirmation") {
          questionAnswerV2.finalConfirmationData = JSON.parse(
            response.data.response
          );
          questionAnswerV2.finalConfirmationData.confirmed = false;
        } else if (response.data.type === "query") {
          questionAnswerV2.query = response.data.response.sql;
          questionAnswerV2.sqlQueryExplanation =
            response.data.response.explanation;
          questionAnswerV2.data = JSON.parse(response.data.response.data);

          if (questionAnswerV2.data && questionAnswerV2.data.length > 0) {
            questionAnswerV2.summaryFetching = true;
            questionAnswerV2.graphFetching = true;
            setFetchSummary(true);
            setFetchGraph(true);
            getFollowUpQuestions(response.data.agentId);
          } else {
            questionAnswerV2.answer = `<span>We don't have data available. Please try something else.</span>
              <span>There are three possibilities:</span>
              <ol style="margin: 0px">
                  <li>You might be looking for something which is stored slightly differently in the database.</li>
                  <li>Database don't have the data you are looking for.</li>
                  <li>The output of your query is NULL.</li>
              </ol>`;
          }
        }
        questionAnswerV2.isLoading = false;
        // console.log(tempQuestionAnswerList, tempQuestionAnswerListV2);
        // setQuestionAnswerList(tempQuestionAnswerList);
        setQuestionAnswerListV2(tempQuestionAnswerListV2);
        if (response.data.chat) {
          setIsNewChat(true);
          chatList.unshift(response.data.chat);
          navigate(`/interact/${connectionConfigId}/${response.data.chat.id}`);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
          setIsLoading(false);
        } else {
          console.log(error);
          var tempQuestionAnswerListV2 = [...questionAnswerListV2];
          var questionAnswerV2 = tempQuestionAnswerListV2.filter(
            (row) => row.id === id
          )[0];
          questionAnswerV2.answer = "Error... Try again";
          questionAnswerV2.isLoading = false;
          // setQuestionAnswerList(tempQuestionAnswerList);
          setQuestionAnswerListV2(tempQuestionAnswerListV2);
          setIsLoading(false);
        }
      });
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: '#e3e3e3',
        },
        // algorithm: darkAlgorithm
      }}
    >
      {
        // (isAuthLoading || !isAuthenticated) ? <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
        // // backgroundColor: "#000"
        // }}>
        // <Spin />
        // </div>: (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "#000", color: '#e3e3e3',
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "15%",
              // borderRight: '1px solid #444746'
            }}
          >
            <SideSegment
              connectionConfigId={connectionConfigId}
              chatId={chatId}
              setQuestionAnswerList={updateTab}
              controllerRef={controllerRef}
              chatList={chatList}
              isChatListLoading={isChatListLoading}
              connectionList={connectionList}
              isConnectionListLoading={isConnectionListLoading}
              setChatList={setChatList}
              next={next}
              setNext={setNext}
              totalChatListLength={totalChatListLength}
              setTotalChatListLength={setTotalChatListLength}
            />
          </div>
          <Divider
            type="vertical"
            style={{
              height: "100%",
              margin: "0px",
            }}
          />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f3f6fc",
              marginLeft: "24px",
              borderRadius: "24px",
            }}
          >
            {/* <div style={{alignSelf:'center', margin:'10px'}}>
              <Slider buttons={[
              {name: "Chat", value: "0", icon: <ThunderboltOutlined />},
              {name: "Insight", value: "1", icon: <MonitorOutlined />},
              ]} defaultValue={tab} onValueChange={(v)=>{setTab(v)}}/>
              </div> */}
            {tab === "0" ? (
              <>
                <div
                  ref={containerRef}
                  style={{
                    flex: 1,
                    padding: "10px 50px 10px 25px",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {connectionConfigId && (
                    <>
                      {isChatLoading || (!chatId && isChatListLoading) ? (
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spin />
                        </div>
                      ) : (
                        <>
                          <ChatV2
                            questionAnswerListV2={questionAnswerListV2}
                            setQuestionAnswerListV2={setQuestionAnswerListV2}
                            getData={getData}
                            callExampleQuestion={callExampleQuestion}
                            sampleQuestionList={sampleQuestionList}
                          />
                          {/* <Chat
                              sampleQuestionList={sampleQuestionList}
                              questionAnswerList={questionAnswerList}
                              setQuestionAnswerList={setQuestionAnswerList}
                              callExampleQuestion={callExampleQuestion}
                            /> */}
                        </>
                      )}
                    </>
                  )}
                </div>
                {FollowUpQuestions && FollowUpQuestions?.length !== 0 && (
                  <div
                    className="hidden-scrollbar"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "auto",
                      gap: 10,
                      justifyContent: "space-between",
                      scrollbarWidth: "none",
                      flexWrap: "nowrap",
                      padding: "0 20px",
                      paddingTop: "10px",
                      marginBottom: "-20px",
                      cursor: "default",
                    }}
                  >
                    {/* <div
                      style={{
                        flex: "0 0 auto",
                        alignSelf: "center",
                        marginLeft: "9px",
                        marginRight: "9px",
                        color: "#777",
                      }}
                    >
                      Explore
                    </div> */}
                    {FollowUpQuestions.map((question: string, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            flex: "0 0 auto",
                            padding: "10px",
                            border: "1px solid #5E5ADB",
                            borderRadius: "20px",
                            color: "#5E5ADB",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            // setFollowUpQuestions([]);
                            getData(question);
                          }}
                        >
                          ✨ {question}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div style={{ margin: "20px 100px" }}>
                  {connectionConfigId && (
                    <SearchBar getData={getData} isLoading={isLoading} />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        // )
      }
    </ConfigProvider>
  );
};
export default Interact;
