import "./App.css";
import PrivateRoute from "./helpers/PrivateRoute";
import React, { useEffect, useState } from "react";
import {
  CodeOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  MoreOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Avatar, Dropdown, Layout, Menu, theme } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Database } from "./screens/database/Database";
import { Widget } from "./screens/Widget";
import { Admin } from "./screens/Admin";
import { NewConnection } from "./screens/database/NewConnection";
import { DatabaseDetail } from "./screens/database/DatabaseDetail/DatabaseDetail";
import Interact from "./screens/interact/Interact";
import { configToUse } from "./Keycloak";

const { Header, Content, Footer, Sider } = Layout;
interface IMenuItemsProps {
  key: string;
  icon: any;
  label: string;
}

function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menuItems: Array<IMenuItemsProps> = [
    {
      key: "",
      icon: <DatabaseOutlined />,
      label: "Database",
    },
    {
      key: "interact",
      icon: <CodeOutlined />,
      label: "Interact",
    },
    {
      key: "widget",
      icon: <DesktopOutlined />,
      label: "Widget",
    },
    {
      key: "admin",
      icon: <UsergroupAddOutlined />,
      label: "Admin",
    },
  ];
  const { keycloak } = useKeycloak();
  const [currentTab, setCurrentTab] = useState(
    window.location.pathname.split("/")[1]
  );
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrentTab(e.key);
    navigate("/" + e.key);
  };

  return (
    <PrivateRoute>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            overflow: "auto",
            height: "100vh",
            background: colorBgContainer,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <div
              style={{ margin: "30px 35px 20px 25px", cursor: "pointer" }}
              // onClick={()=>{window.open('https://getcrux.ai/', '_blank')}}
            >
              <img
                src={configToUse.REACT_APP_SMALL_LOGO}
                style={{ width: "35px", display: collapsed ? "block" : "none" }}
              />
              <img
                src={configToUse.REACT_APP_BIG_LOGO}
                style={{
                  width: "105px",
                  display: collapsed ? "none" : "block",
                }}
              />
            </div>
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[currentTab]}
              onClick={onClick}
              style={{ flex: 1 }}
            >
              {menuItems.map((row) =>
                keycloak.hasRealmRole(row.label) ? (
                  <Menu.Item key={row.key} icon={row.icon}>
                    {row.label}
                  </Menu.Item>
                ) : (
                  <></>
                )
              )}
            </Menu>
            <div
              style={{
                margin: collapsed
                  ? "15px 10px 60px 20px"
                  : "15px 10px 60px 10px",
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              {collapsed ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="logout">
                        <a
                          onClick={() => {
                            keycloak.logout();
                          }}
                        >
                          Logout
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["hover"]}
                >
                  <Avatar
                    style={{
                      backgroundColor: "#ff6000",
                      verticalAlign: "middle",
                    }}
                    size="default"
                  >
                    {keycloak.idTokenParsed?.preferred_username
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                </Dropdown>
              ) : (
                <>
                  <Avatar
                    style={{
                      backgroundColor: "#ff6000",
                      verticalAlign: "middle",
                    }}
                    size="default"
                  >
                    {keycloak.idTokenParsed?.preferred_username
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                      flex: 1,
                    }}
                  >
                    {keycloak.idTokenParsed?.preferred_username}
                  </div>

                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="logout">
                          <a
                            onClick={() => {
                              keycloak.logout();
                            }}
                          >
                            Logout
                          </a>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["hover"]}
                  >
                    <MoreOutlined style={{ cursor: "pointer" }} />
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </Sider>
        <Layout>
          <Content
            style={{
              margin: "0 16px 0",
              overflow: "initial",
              display: "flex",
            }}
          >
            <div
              style={{
                padding: 24,
                height: "100vh",
                background: colorBgContainer,
                width: "-webkit-fill-available",
                fontFamily: "Optimo Plain",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Routes>
                <Route path="" element={<Database />} />
                <Route path="/new-connection" element={<NewConnection />} />
                <Route
                  path="/connection/:connectionId"
                  element={<DatabaseDetail />}
                />
                <Route
                  path="/interact/:connectionConfigId?/:chatId?"
                  element={<Interact />}
                />
                <Route path="/widget" element={<Widget />} />
                <Route path="/admin" element={<Admin />} />
              </Routes>
            </div>
          </Content>
          {/* <Footer style={{ textAlign: "center", padding: "20px" }}>
            Crux ©2023
          </Footer> */}
        </Layout>
      </Layout>
    </PrivateRoute>
  );
}

export default App;
