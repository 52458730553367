import {
  Button,
  Collapse,
  Divider,
  Input,
  Modal,
  Select,
  Switch,
  notification,
} from "antd";
import { CreateDBTableModal } from "./CreateDBTableModal";
import { IConnectionDbColumnConfig } from "./DatabaseDetail";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../NewConnection";
import { useParams } from "react-router-dom";
import { NotificationType } from "./DBConfig";
import { UpdateDBTableModal } from "./UpdateDBTableModal";

export interface ISheetDbTableMap {
  table: string;
  value: Array<{ value: string; column_name: string; column_letter: string }>;
  disabled?: boolean;
}

export interface IGoogleSheetConfigs {
  id?: string;
  sheet_url: string;
  sub_sheet_name: string;
  column_row_number?: number;
  sheet_db_table_map: Array<ISheetDbTableMap>;
}

interface IGoogleSheetConfigProps {
  googleSheetConfigs: Array<IGoogleSheetConfigs>;
  setGoogleSheetConfigs: (
    googleSheetConfig: Array<IGoogleSheetConfigs>
  ) => void;
  connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  };
  setConnectionDbConfig: (connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  }) => void;
}
interface ILoadingConfig {
  updateData?: boolean;
  pullHeaderFromSheet?: { [key: string]: boolean };
  saveConfig?: { [key: string]: boolean };
  addSheet?: { [key: string]: boolean };
}
export const GoogleSheetConfig = (props: IGoogleSheetConfigProps) => {
  const { connectionId } = useParams();
  const {
    googleSheetConfigs,
    setGoogleSheetConfigs,
    connectionDbConfig,
    setConnectionDbConfig,
  } = props;
  const [openCreateGoogleSheetModal, setOpenCreateGoogleSheetModal] =
    useState(false);
  const [openUpdateGoogleSheetModal, setOpenUpdateGoogleSheetModal] =
    useState(false);
  const [loadingConfig, setLoadingConfig] = useState<ILoadingConfig>();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
    });
  };

  const handleSelectDBTableChnage = (
    sheetIndex: number,
    tableIndex: number,
    value: string
  ) => {
    const tmpGoogleSheetConfigs = [...googleSheetConfigs];
    tmpGoogleSheetConfigs[sheetIndex].sheet_db_table_map[tableIndex].table =
      value;
    tmpGoogleSheetConfigs[sheetIndex].sheet_db_table_map[
      tableIndex
    ].value.forEach((row) => {
      if (
        connectionDbConfig[value]
          .map((dbConfig) => dbConfig.name.toLowerCase().replace(" ", "_"))
          .includes(row.value.toLowerCase().replace(" ", "_"))
      ) {
        row.column_name = row.value.toLowerCase().replace(" ", "_");
      } else {
        row.column_name = "";
      }
    });
    setGoogleSheetConfigs(tmpGoogleSheetConfigs);
  };

  const handleTableConfigChange = (
    sheetIndex: number,
    tableIndex: number,
    columnIndex: number,
    value: string
  ) => {
    const tmpGoogleSheetConfigs = [...googleSheetConfigs];
    tmpGoogleSheetConfigs[sheetIndex].sheet_db_table_map[tableIndex].value[
      columnIndex
    ].column_name = value;
    setGoogleSheetConfigs(tmpGoogleSheetConfigs);
  };

  const handleAddNewSheet = () => {
    const tmpGoogleSheetConfigs = [...googleSheetConfigs];
    tmpGoogleSheetConfigs.push({
      sheet_url: "",
      sub_sheet_name: "",
      sheet_db_table_map: [],
    });
    setGoogleSheetConfigs(tmpGoogleSheetConfigs);
  };

  const pullHeaderFromSheet = (gsheetId: string, sheetIndex: number) => {
    setLoadingConfig({
      ...loadingConfig,
      pullHeaderFromSheet: { [gsheetId + "-" + sheetIndex]: true },
    });
    axios
      .get(`${baseUrl}/connection/${connectionId}/gsheet/${gsheetId}/refresh/`)
      .then(function (response) {
        setLoadingConfig({
          ...loadingConfig,
          pullHeaderFromSheet: { [gsheetId + "-" + sheetIndex]: false },
        });
        openNotificationWithIcon("success", "Header data pulled from sheet!");
        const tmpGoogleSheetConfigs = [...googleSheetConfigs];
        tmpGoogleSheetConfigs[sheetIndex] = response.data;
        setGoogleSheetConfigs(tmpGoogleSheetConfigs);
      })
      .catch(function (error) {
        setLoadingConfig({
          ...loadingConfig,
          pullHeaderFromSheet: { [gsheetId + "-" + sheetIndex]: false },
        });
        console.log(error.response.data.error);
        openNotificationWithIcon("error", "Failed!");
        //   openNotificationWithIcon("error", error.response.data.error);
        //   setIsNewConnectionLoading(false);
      });
  };

  const addSheet = (sheetIndex: number) => {
    setLoadingConfig({
      ...loadingConfig,
      addSheet: { [sheetIndex]: true },
    });
    axios
      .post(`${baseUrl}/connection/${connectionId}/gsheet/add/`, {
        sheetUrl: googleSheetConfigs[sheetIndex].sheet_url,
        subSheetName: googleSheetConfigs[sheetIndex].sub_sheet_name,
        columnRowNumber: googleSheetConfigs[sheetIndex].column_row_number,
      })
      .then(function (response) {
        console.log(response);
        setLoadingConfig({
          ...loadingConfig,
          pullHeaderFromSheet: { [sheetIndex]: false },
        });
        openNotificationWithIcon("success", "Sheet Added!");
        const tmpGoogleSheetConfigs = [...googleSheetConfigs];
        tmpGoogleSheetConfigs[sheetIndex] = response.data;
        setGoogleSheetConfigs(tmpGoogleSheetConfigs);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        openNotificationWithIcon("error", "Failed!");
        setLoadingConfig({
          ...loadingConfig,
          pullHeaderFromSheet: { [sheetIndex]: false },
        });
        //   openNotificationWithIcon("error", error.response.data.error);
        //   setIsNewConnectionLoading(false);
      });
  };

  const saveConfig = (gsheetId: string, sheetIndex: number) => {
    setLoadingConfig({
      ...loadingConfig,
      saveConfig: { [gsheetId + "-" + sheetIndex]: true },
    });
    axios
      .post(
        `${baseUrl}/connection/${connectionId}/gsheet/${gsheetId}/refresh/`,
        { sheetDbTableMap: googleSheetConfigs[sheetIndex].sheet_db_table_map }
      )
      .then(function (response) {
        setLoadingConfig({
          ...loadingConfig,
          saveConfig: { [gsheetId + "-" + sheetIndex]: false },
        });
        console.log(response);
        const tmpGoogleSheetConfigs = [...googleSheetConfigs];
        tmpGoogleSheetConfigs[sheetIndex] = response.data;
        setGoogleSheetConfigs(tmpGoogleSheetConfigs);
        openNotificationWithIcon("success", "Config Saved!");
      })
      .catch(function (error) {
        setLoadingConfig({
          ...loadingConfig,
          saveConfig: { [gsheetId + "-" + sheetIndex]: false },
        });
        console.log(error.response.data.error);
        openNotificationWithIcon("error", "Failed!");
        //   setIsNewConnectionLoading(false);
      });
  };

  const updateData = () => {
    setLoadingConfig({ ...loadingConfig, updateData: true });
    axios
      .get(`${baseUrl}/connection/${connectionId}/gsheet/update-data/`)
      .then(function (response) {
        setLoadingConfig({ ...loadingConfig, updateData: false });
        openNotificationWithIcon("success", "Database Updated!");
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setLoadingConfig({ ...loadingConfig, updateData: false });
        openNotificationWithIcon("error", "Failed!");
        //   setIsNewConnectionLoading(false);
      });
  };

  return (
    <>
      {" "}
      {contextHolder}
      <div style={{ display: "flex", gap: 10, justifyContent: "right" }}>
        <Button
          type="primary"
          //   loading={isConnectionDbConfigUpdating}
          onClick={() =>
            setOpenUpdateGoogleSheetModal(!openUpdateGoogleSheetModal)
          }
        >
          Edit DB Table
        </Button>
        <Button
          type="primary"
          //   loading={isConnectionDbConfigUpdating}
          onClick={() =>
            setOpenCreateGoogleSheetModal(!openCreateGoogleSheetModal)
          }
        >
          New DB Table
        </Button>
        <Button
          type="primary"
          loading={loadingConfig?.updateData}
          onClick={() => updateData()}
        >
          Update Data
        </Button>
      </div>
      <br />
      <div style={{ height: "80vh" }}>
        <div style={{ overflowY: "scroll", height: "100%" }}>
          {googleSheetConfigs.map((googleSheetConfig, sheetIndex) => (
            <>
              <div style={{ display: "flex", width: "100%", gap: 20 }}>
                <div style={{ flex: 2 }}>
                  <p style={{ marginBottom: "5px" }}>Sheet Url</p>
                  <Input
                    value={googleSheetConfig.sheet_url}
                    onChange={(e) => {
                      const tmpGoogleSheetConfigs = [...googleSheetConfigs];
                      tmpGoogleSheetConfigs[sheetIndex].sheet_url =
                        e.target.value;
                      setGoogleSheetConfigs(tmpGoogleSheetConfigs);
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: "5px" }}>Sub-Sheet Name</p>
                  <Input
                    value={googleSheetConfig.sub_sheet_name}
                    onChange={(e) => {
                      const tmpGoogleSheetConfigs = [...googleSheetConfigs];
                      tmpGoogleSheetConfigs[sheetIndex].sub_sheet_name =
                        e.target.value;
                      setGoogleSheetConfigs(tmpGoogleSheetConfigs);
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: "5px" }}>Header Row Number</p>
                  <Input
                    type="number"
                    value={googleSheetConfig.column_row_number}
                    onChange={(e) => {
                      const tmpGoogleSheetConfigs = [...googleSheetConfigs];
                      tmpGoogleSheetConfigs[sheetIndex].column_row_number =
                        parseInt(e.target.value);
                      setGoogleSheetConfigs(tmpGoogleSheetConfigs);
                    }}
                  />
                </div>
              </div>
              <br />
              {googleSheetConfig.id ? (
                <>
                  <Collapse
                    items={googleSheetConfig.sheet_db_table_map?.map(
                      (row, tableIndex) => ({
                        key: tableIndex,
                        label: (
                          <div style={{ display: "flex", gap: 10 }}>
                            <div style={{ flex: 1 }}>
                              Table {tableIndex + 1} (
                              {row.value[0].column_letter}:
                              {row.value[row.value.length - 1].column_letter})
                            </div>
                            <Select
                              disabled={row.disabled}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Select DB Tabe"
                              value={row.table ? row.table : null}
                              style={{ width: "200px" }}
                              onChange={(v) =>
                                handleSelectDBTableChnage(
                                  sheetIndex,
                                  tableIndex,
                                  v
                                )
                              }
                              options={
                                connectionDbConfig
                                  ? Object.keys(connectionDbConfig).map(
                                      (value) => ({
                                        value: value,
                                        label: value,
                                      })
                                    )
                                  : []
                              }
                            />
                            <div
                              style={{ alignSelf: "center" }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Switch
                                defaultChecked={!row.disabled}
                                onChange={(value) => {
                                  const tmpGoogleSheetConfigs = [
                                    ...googleSheetConfigs,
                                  ];
                                  tmpGoogleSheetConfigs[
                                    sheetIndex
                                  ].sheet_db_table_map[tableIndex].disabled =
                                    !value;
                                  setGoogleSheetConfigs(tmpGoogleSheetConfigs);
                                }}
                              />
                            </div>
                          </div>
                        ),
                        children: (
                          <div style={{ display: "flex", gap: 10 }}>
                            {row.value.map((conf, confIndex) => (
                              <div style={{ flex: 1 }}>
                                <p style={{ marginBottom: "5px" }}>
                                  {conf.value}
                                </p>
                                <Select
                                  style={{ width: "100%" }}
                                  disabled={row.table === "" || row.disabled}
                                  value={
                                    conf.column_name ? conf.column_name : ""
                                  }
                                  onChange={(v) => {
                                    handleTableConfigChange(
                                      sheetIndex,
                                      tableIndex,
                                      confIndex,
                                      v
                                    );
                                  }}
                                  options={
                                    row.table
                                      ? connectionDbConfig[row.table].map(
                                          (dbConfig) => ({
                                            label: dbConfig.name,
                                            value: dbConfig.name,
                                          })
                                        )
                                      : []
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        ),
                      })
                    )}
                  />
                  <br />
                  <div style={{ display: "flex", float: "right", gap: 10 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        pullHeaderFromSheet(googleSheetConfig.id!, sheetIndex);
                      }}
                      loading={
                        loadingConfig?.pullHeaderFromSheet &&
                        loadingConfig.pullHeaderFromSheet[
                          googleSheetConfig.id + "-" + sheetIndex
                        ]
                      }
                    >
                      Pull header from sheet
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        saveConfig(googleSheetConfig.id!, sheetIndex);
                      }}
                      loading={
                        loadingConfig?.saveConfig &&
                        loadingConfig.saveConfig[
                          googleSheetConfig.id + "-" + sheetIndex
                        ]
                      }
                    >
                      Save Config
                    </Button>
                  </div>
                </>
              ) : (
                <div style={{ display: "flex", float: "right", gap: 10 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      addSheet(sheetIndex);
                    }}
                    loading={
                      loadingConfig?.addSheet &&
                      loadingConfig?.addSheet[sheetIndex]
                    }
                  >
                    Add Sheet
                  </Button>
                </div>
              )}
              <br />
              <Divider />
            </>
          ))}
          <Button type="primary" onClick={handleAddNewSheet}>
            + New Sheet
          </Button>
        </div>
      </div>
      <br />
      <CreateDBTableModal
        openCreateGoogleSheetModal={openCreateGoogleSheetModal}
        setOpenCreateGoogleSheetModal={setOpenCreateGoogleSheetModal}
        sheetTables={
          googleSheetConfigs.length > 0
            ? googleSheetConfigs[0].sheet_db_table_map
            : []
        }
        setConnectionDbConfig={setConnectionDbConfig}
      />
      <UpdateDBTableModal
        openUpdateGoogleSheetModal={openUpdateGoogleSheetModal}
        setOpenUpdateGoogleSheetModal={setOpenUpdateGoogleSheetModal}
        connectionDbConfig={connectionDbConfig}
        setConnectionDbConfig={setConnectionDbConfig}
      />
    </>
  );
};
