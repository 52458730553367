import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LiveWidget } from "./screens/LiveWidget";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="*"
        element={
          <ReactKeycloakProvider
            initOptions={{ onLoad: "login-required" }}
            authClient={keycloak}
            onTokens={({ token }) => {
              console.log(token);
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
            }}
          >
            <App />
          </ReactKeycloakProvider>
        }
      />
      <Route path="/live-widget/:widgetId" element={<LiveWidget />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
