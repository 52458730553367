import Keycloak from 'keycloak-js';
import {getConfig} from './config'

export const configToUse = getConfig(process.env.REACT_APP_SCENARIO);

const keycloak = new Keycloak({
  url: configToUse.REACT_APP_KEYCLOAK_URL,
  realm: configToUse.REACT_APP_KEYCLOAK_REALM,
  clientId: configToUse.REACT_APP_KEYCLOAK_REALM_CLIENT_ID,
  'enable-cors': true,
});

export default keycloak;