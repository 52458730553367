import { Button, Input, Modal, Select, notification } from "antd";
import { useState } from "react";
import { ISheetDbTableMap } from "./GoogleSheetConfig";
import axios from "axios";
import { baseUrl } from "../NewConnection";
import { useParams } from "react-router-dom";
import { NotificationType } from "./DBConfig";
import { IConnectionDbColumnConfig } from "./DatabaseDetail";

export enum ECreateTableValueTypes {
  DATE = "DATE",
  INT = "INT",
  DECIMAL = "DECIMAL",
  STRING = "STRING",
}
interface ICreateTableValueProps {
  columnName: string;
  type?: ECreateTableValueTypes;
}

export interface ICreateTableConfig {
  tableName: string;
  values: Array<ICreateTableValueProps>;
}

interface ICreateDBTableModal {
  sheetTables: Array<ISheetDbTableMap>;
  openCreateGoogleSheetModal: boolean;
  setOpenCreateGoogleSheetModal: (openCreateGoogleSheetModal: boolean) => void;
  setConnectionDbConfig: (connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  }) => void;
}
export const CreateDBTableModal = (props: ICreateDBTableModal) => {
  const {
    sheetTables,
    openCreateGoogleSheetModal,
    setOpenCreateGoogleSheetModal,
    setConnectionDbConfig,
  } = props;
  const [createTableConfig, setCreateTableConfig] =
    useState<ICreateTableConfig>({
      tableName: "",
      values: [],
    });
  const [isNewDbCreateLoading, setIsNewDbCreateLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const { connectionId } = useParams();
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
    });
  };

  const addColumn = () => {
    const tmpCreateTableConfig = { ...createTableConfig };
    tmpCreateTableConfig.values.push({
      columnName: "",
    });
    setCreateTableConfig(tmpCreateTableConfig);
  };

  const handleSelectSheetChange = (index: number) => {
    const tmpCreateTableConfig = { ...createTableConfig };
    tmpCreateTableConfig.values = sheetTables[index].value.map((row) => ({
      columnName: row.value.toLowerCase().replace(" ", "_"),
    }));
    setCreateTableConfig(tmpCreateTableConfig);
  };

  const handleTableNameChange = (value: string) => {
    const tmpCreateTableConfig = { ...createTableConfig };
    tmpCreateTableConfig.tableName = value;
    setCreateTableConfig(tmpCreateTableConfig);
  };

  const handleColumnNameChange = (index: number, value: string) => {
    const tmpCreateTableConfig = { ...createTableConfig };
    tmpCreateTableConfig.values[index].columnName = value;
    setCreateTableConfig(tmpCreateTableConfig);
  };

  const handleTypeChange = (index: number, value: ECreateTableValueTypes) => {
    const tmpCreateTableConfig = { ...createTableConfig };
    tmpCreateTableConfig.values[index].type = value;
    setCreateTableConfig(tmpCreateTableConfig);
  };

  const createDBTable = () => {
    setIsNewDbCreateLoading(true);
    axios
      .post(
        `${baseUrl}/connection/${connectionId}/gsheet/new-table/`!,
        createTableConfig
      )
      .then(function (response) {
        console.log(response);
        setConnectionDbConfig(response.data.db_config);
        setIsNewDbCreateLoading(false);
        setOpenCreateGoogleSheetModal(false);
        openNotificationWithIcon("success", "Table Created!");
        //   setIsNewConnectionLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsNewDbCreateLoading(false);
        openNotificationWithIcon("error", error.response.data.error);
        //   setIsNewConnectionLoading(false);
      });
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={openCreateGoogleSheetModal}
        centered
        width={"60vw"}
        title="Create New DB Table"
        // onOk={handleOk}
        onCancel={() => {
          setOpenCreateGoogleSheetModal(false);
        }}
        footer={[
          <Button
            type="primary"
            key="back"
            onClick={createDBTable}
            loading={isNewDbCreateLoading}
          >
            Create DB Table
          </Button>,
        ]}
      >
        <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <p style={{ marginBottom: "5px" }}>DB Table Name</p>
          <Input
            value={createTableConfig?.tableName}
            onChange={(e) => {
              handleTableNameChange(e.target.value);
            }}
          />
          <br />
          <p style={{ marginBottom: "5px" }}>
            Select Sheet Table(to auto add columns)
          </p>
          <Select
            placeholder="Select Sheet Tabe"
            style={{ width: "100%" }}
            onChange={handleSelectSheetChange}
            options={sheetTables?.map((sheetTable, index) => ({
              value: index,
              label: (
                <>
                  Table {index + 1} ({sheetTable.value[0]?.column_letter}:
                  {sheetTable.value[sheetTable.value.length - 1]?.column_letter}
                  )
                </>
              ),
            }))}
          />
          {createTableConfig.values.map((row, index) => (
            <>
              <div style={{ display: "flex", gap: 10 }}>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: "5px" }}>Column Name</p>
                  <Input
                    value={row.columnName}
                    onChange={(e) =>
                      handleColumnNameChange(index, e.target.value)
                    }
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: "5px" }}>Type</p>
                  <Select
                    key={index}
                    placeholder="Select column type"
                    style={{ width: "100%" }}
                    value={row.type}
                    onChange={(value) => {
                      handleTypeChange(index, value);
                    }}
                    options={(
                      Object.keys(ECreateTableValueTypes) as Array<
                        keyof typeof ECreateTableValueTypes
                      >
                    ).map((key) => ({
                      value: ECreateTableValueTypes[key],
                      label: key.charAt(0) + key.slice(1).toLowerCase(), // Convert key to label format
                    }))}
                  />
                </div>
              </div>
            </>
          ))}
          <div>
            <br />
            <Button onClick={addColumn}>+ Add Column</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
