import { ThunderboltOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { useState } from "react";

export const Slider = (props) => {
    const {buttons, defaultValue, onValueChange} = props;
    // const [value, setValue] = useState(defaultValue)

    return(<>
    <div style={{ display: 'flex', justifyContent: 'center', gap: '6px', width:'fit-content', 
    backgroundColor: '#e3e3e3',
     borderRadius:'8px', padding: '5px', display: 'flex', flexDirection: 'row', gap:'4px'}}>
        {
            buttons.map(row=>(
                <Badge count={row.count}>
                    <div style={{width: '100px', 
                        backgroundColor: row.value === defaultValue ? '#f8fafd': null, 
                        // border: row.value === defaultValue ? '1px solid rgba(78,79,96, 0.4)': '1px solid #000', 
                        // color: '#fff',
                        borderRadius:'6px', 
                        padding: '5px', 
                        fontSize: '16px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={()=>{ onValueChange(row.value)}}
                    >
                        {row.icon}<span>{" "}{row.name}</span>
                    </div>
                </Badge>
            ))
        }
    </div>
    </>)
}