import axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { baseUrl } from "./NewConnection";
import { Avatar, Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";

export interface IConnectionProps {
  id: string;
  name: string;
  host: string;
  database: string;
  author: string;
}

export const Database = () => {
  const [isConnectionListLoading, setIsConnectionListLoading] = useState(true);
  const [connectionList, setConnectionList] = useState<Array<IConnectionProps>>(
    []
  );
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.hasRealmRole("Database")) {
      navigate("/interact");
      return;
    }
    axios
      .get(`${baseUrl}/connection/all/`)
      .then(function (response) {
        setConnectionList(response.data);
        setIsConnectionListLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionListLoading(false);
      });
  }, []);

  const columns: ColumnsType<IConnectionProps> = [
    {
      title: "Connection Name",
      dataIndex: "name",
      key: "name",
      render: (text, { id }) => (
        <a
          onClick={() => {
            navigate("/interact/" + id);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host",
    },
    {
      title: "Database",
      dataIndex: "database",
      key: "database",
    },
    {
      title: "Author",
      key: "author",
      dataIndex: "author",
      ellipsis: true,
      render: (_, { author }) => (
        <>
          <div
            style={{
              margin: "15px",
              display: "flex",
              gap: 5,
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#ff6000",
                verticalAlign: "middle",
              }}
              size="default"
            >
              {author.charAt(0).toUpperCase()}
            </Avatar>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                flex: 1,
              }}
            >
              {author}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, { id }) => (
        <a
          onClick={() => {
            navigate("/connection/" + id);
          }}
        >
          View Catalog
        </a>
      ),
    },
  ];
  const [tableHeight, setTableHeight] = useState(600);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    const { top } = node!.getBoundingClientRect();
    setTableHeight(window.innerHeight - top - 120);
  }, [ref]);
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          <h2 style={{ marginTop: "0" }}>Database Connections</h2>
        </div>
        <Button
          onClick={() => {
            navigate("/new-connection/");
          }}
          type="primary"
          style={{ marginBottom: 16 }}
          icon={<PlusOutlined />}
        >
          New Connection
        </Button>
      </div>
      <Table
        ref={ref}
        style={{ flex: 1 }}
        columns={columns}
        dataSource={connectionList}
        loading={isConnectionListLoading}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
};
