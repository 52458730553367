import {
  DatabaseFilled,
  DownloadOutlined,
  SendOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import DBShortView from "./DBShortView";
import { useParams } from "react-router-dom";

export const SearchBar = (props) => {
  const { getData, isLoading } = props;
  const { connectionConfigId } = useParams();
  const [inputVal, setInputVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(
    JSON.parse(localStorage.getItem("OpenModalOnStart") || "{}")[
      connectionConfigId
    ] !== undefined
      ? JSON.parse(localStorage.getItem("OpenModalOnStart"))[connectionConfigId]
      : true
  );
  const [showCheckbox, setShowCheckbox] = useState(
    JSON.parse(localStorage.getItem("OpenModalOnStart") || "{}")[
      connectionConfigId
    ] !== undefined
      ? JSON.parse(localStorage.getItem("OpenModalOnStart"))[connectionConfigId]
      : true
  );

  const [modalInput, setModalInput] = useState("");

  useEffect(() => {
    setIsModalOpen(
      JSON.parse(localStorage.getItem("OpenModalOnStart") || "{}")[
        connectionConfigId
      ] !== undefined
        ? JSON.parse(localStorage.getItem("OpenModalOnStart"))[
            connectionConfigId
          ]
        : true
    );
    setShowCheckbox(
      JSON.parse(localStorage.getItem("OpenModalOnStart") || "{}")[
        connectionConfigId
      ] !== undefined
        ? JSON.parse(localStorage.getItem("OpenModalOnStart"))[
            connectionConfigId
          ]
        : true
    );
    console.log(
      JSON.parse(localStorage.getItem("OpenModalOnStart") || "{}")[
        connectionConfigId
      ] !== undefined
        ? JSON.parse(localStorage.getItem("OpenModalOnStart"))[
            connectionConfigId
          ]
        : true
    );
  }, [connectionConfigId]);

  const showModal = () => {
    if (!isLoading) {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setInputVal("");
  }, [isLoading]);
  return (
    <div style={{ display: "flex" }}>
      <Input
        disabled={isLoading}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        suffix={
          <DatabaseFilled
            type="primary"
            disabled={isLoading}
            onClick={showModal}
          />
        }
        style={{
          padding: "10px 25px",
          borderRadius: "100px",
          fontSize: "14px",
          fontFamily: "Poppins, sans-serif",
        }}
        placeholder="Send a message..."
        onPressEnter={() => {
          if (inputVal) {
            getData(inputVal);
          }
        }}
      />
      <Modal
        centered
        // style={{ height: "80vh", top: 0 }}
        width={900}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        title="Database Details"
      >
        <DBShortView
          modalInput={modalInput}
          getData={getData}
          setIsModalOpen={setIsModalOpen}
          setModalInput={setModalInput}
          showCheckBox={showCheckbox}
        />
      </Modal>
      <SendOutlined
        style={{
          fontSize: "21px",
          marginLeft: "15px",
          // color: inputVal?'rgba(227,227,227,1)':'rgba(227,227,227,0.38)',
          cursor: inputVal ? "pointer" : "not-allowed",
        }}
        onClick={() => {
          getData(inputVal);
        }}
      />
    </div>
  );
};
