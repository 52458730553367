import { Spin } from "antd";

const CustomLoader = (props: { isLoading: boolean; text: string }) => {
  const { isLoading, text } = props;
  return (
    <div
      style={{
        background: "rgb(243, 246, 252)",
        padding: "10px 20px",
        display: "flex",
        gap: 10,
        width: "fit-content",
        justifyContent: "space-around",
        borderRadius: "10px",
        alignItems: "center",
      }}
    >
      <div style={{ display: "block" }}>{text + " ..."}</div>
      <Spin style={{ display: "block" }} size="small" />
    </div>
  );
};

export default CustomLoader;
