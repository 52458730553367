import axios from "axios";
import { ReactNode, useEffect, useState } from "react";
import { baseUiUrl, baseUrl } from "./database/NewConnection";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import {
  DownloadOutlined,
  FilterFilled,
  FilterTwoTone,
} from "@ant-design/icons";

export const Widget = () => {
  const [isWidgetListLoading, setIsWidgetListLoading] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [widgetList, setWidgetList] = useState<any>([]);
  const [modalId, setModalId] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [tagList, setTagList] = useState<Array<string>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [connList, setConnList] = useState<Array<string>>([]);
  const [selectedConns, setSelectedConns] = useState<Array<string>>([]);
  const [authorList, setAuthorList] = useState<Array<string>>([]);
  const [selectedAuthors, setSelectedAuthors] = useState<Array<string>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [authorFilterDropdownOpen, setAuthorFilterDropdownOpen] =
    useState<boolean>(false);
  const [tagFilterDropdownOpen, setTagFilterDropdownOpen] =
    useState<boolean>(false);
  const [connFilterDropdownOpen, setConnFilterDropdownOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isMounted) {
      axios
        .get(`${baseUrl}/chat/v3/widget/all/?items_per_page=${itemsPerPage}`)
        .then(function (response) {
          const tagSet = new Set();
          response.data.tags?.forEach((obj: any) => {
            obj?.forEach((tag: string) => tagSet.add(tag));
          });
          var tags = Array.from(tagSet) as Array<string>;
          setTagList(tags);
          const connSet = new Set();
          response.data.connections?.forEach((conn: any) => {
            connSet.add(conn);
          });
          var conns = Array.from(connSet) as Array<string>;
          setConnList(conns);
          const authorSet = new Set();
          response.data.authors?.forEach((obj: any) => {
            obj?.forEach((author: string) => authorSet.add(author));
          });
          var authors = Array.from(authorSet) as Array<string>;
          setAuthorList(authors);
          setWidgetList(response.data.results);
          setTotalCount(response.data.count);
          setIsWidgetListLoading(false);
          setIsMounted(true);
        })
        .catch(function (e) {
          setIsWidgetListLoading(false);
          setIsMounted(true);
        });
    }
  }, [isMounted]);

  const fetchPage = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setAuthorFilterDropdownOpen(false);
    setConnFilterDropdownOpen(false);
    setTagFilterDropdownOpen(false);
    setItemsPerPage(pageSize);
    setIsWidgetListLoading(true);
    var paramSearchString = `?items_per_page=${itemsPerPage}&page=${page}`;
    if (selectedTags.length) {
      paramSearchString += "&tags=";
      for (var i = 0; i < selectedTags.length; i++) {
        paramSearchString += selectedTags[i];
        if (i + 1 !== selectedTags.length) paramSearchString += ",";
      }
    }
    if (selectedConns.length) {
      paramSearchString += "&connections=";
      for (var i = 0; i < selectedConns.length; i++) {
        paramSearchString += selectedConns[i];
        if (i + 1 !== selectedConns.length) paramSearchString += ",";
      }
    }
    if (selectedAuthors.length) {
      paramSearchString += "&authors=";
      for (var i = 0; i < selectedAuthors.length; i++) {
        paramSearchString += selectedAuthors[i];
        if (i + 1 !== selectedAuthors.length) paramSearchString += ",";
      }
    }
    axios
      .get(`${baseUrl}/chat/v3/widget/all/${paramSearchString}`)
      .then(function (response) {
        setWidgetList(response.data.results);
        setIsWidgetListLoading(false);
      })
      .catch(function (e) {
        setIsWidgetListLoading(false);
      });
  };

  const getFilterSet = (key: string) => {
    const distinctObjects: Array<string> = [];
    const idSet = new Set();

    widgetList.forEach((obj: any) => {
      if (!idSet.has(obj[key])) {
        idSet.add(obj[key]);
        distinctObjects.push(obj[key]);
      }
    });
    return distinctObjects;
  };

  const getFilteredData = () => {
    setAuthorFilterDropdownOpen(false);
    setConnFilterDropdownOpen(false);
    setTagFilterDropdownOpen(false);
    setIsWidgetListLoading(true);
    var paramSearchString = `?items_per_page=${itemsPerPage}`;
    if (selectedTags.length) {
      paramSearchString += "&tags=";
      for (var i = 0; i < selectedTags.length; i++) {
        paramSearchString += selectedTags[i];
        if (i + 1 !== selectedTags.length) paramSearchString += ",";
      }
    }
    if (selectedConns.length) {
      paramSearchString += "&connections=";
      for (var i = 0; i < selectedConns.length; i++) {
        paramSearchString += selectedConns[i];
        if (i + 1 !== selectedConns.length) paramSearchString += ",";
      }
    }
    if (selectedAuthors.length) {
      paramSearchString += "&authors=";
      for (var i = 0; i < selectedAuthors.length; i++) {
        paramSearchString += selectedAuthors[i];
        if (i + 1 !== selectedAuthors.length) paramSearchString += ",";
      }
    }
    axios
      .get(`${baseUrl}/chat/v3/widget/all/${paramSearchString}`)
      .then(function (response) {
        setWidgetList(response.data.results);
        setTotalCount(response.data.count);
        setIsWidgetListLoading(false);
      })
      .catch(function (e) {
        setIsWidgetListLoading(false);
      });
  };

  const getTagFilterSet = () => {
    const distinctIdSet = new Set();

    widgetList?.forEach((obj: any) => {
      obj.tags?.forEach((tag: string) => distinctIdSet.add(tag));
    });

    return Array.from(distinctIdSet) as Array<string>;
  };

  const columns: ColumnsType<{
    id: string;
    title: string;
    connection_config_name: string;
    user_email: string;
    tags: Array<string>;
  }> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      // render: (text, { id }) => (
      //   <a
      //     onClick={() => {
      //       navigate("/connection/" + id);
      //     }}
      //   >
      //     {text}
      //   </a>
      // ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Connection Name",
      dataIndex: "connection_config_name",
      key: "connection_config_name",
      filters: connList.map((value) => ({
        text: value,
        value: value,
      })),
      filterDropdown: (
        <div style={{ display: "flex", flexDirection: "column", padding: 8 }}>
          <Checkbox.Group
            options={connList}
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px", // Set a maximum height
              overflowY: "auto",
            }}
            value={selectedConns}
            onChange={(checkedValues: any) => {
              setSelectedConns(checkedValues);
            }}
          />
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxHeight: "200px", // Set a maximum height
              overflowY: "auto",
            }}
          >
            <Button
              size="small"
              type="default"
              onClick={(e) => setSelectedConns([])}
            >
              Reset
            </Button>
            <Button size="small" type="primary" onClick={getFilteredData}>
              OK
            </Button>
          </div>
        </div>
      ),
      filterDropdownOpen: connFilterDropdownOpen,
      filterIcon: (
        <FilterTwoTone
          twoToneColor={!selectedConns.length ? "grey" : "#1890ff"}
          onClick={(e) => setConnFilterDropdownOpen(!connFilterDropdownOpen)}
        />
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      filters: tagList.map((value) => ({
        text: value,
        value: value,
      })),
      onFilter: (value: any, record) => {
        console.log(value, record);
        return record.tags.includes(value);
      },
      render: (tags) => (
        <>
          {tags?.map((row: string) => (
            <Tag>{row}</Tag>
          ))}
        </>
      ),
      filterDropdown: (
        <div style={{ display: "flex", flexDirection: "column", padding: 8 }}>
          <Checkbox.Group
            options={tagList}
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px", // Set a maximum height
              overflowY: "auto",
            }}
            value={selectedTags}
            onChange={(checkedValues: any) => {
              setSelectedTags(checkedValues);
            }}
          />
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              type="default"
              onClick={(e) => setSelectedTags([])}
            >
              Reset
            </Button>
            <Button size="small" type="primary" onClick={getFilteredData}>
              OK
            </Button>
          </div>
        </div>
      ),
      filterDropdownOpen: tagFilterDropdownOpen,
      filterIcon: (
        <FilterTwoTone
          twoToneColor={!selectedTags.length ? "grey" : "#1890ff"}
          onClick={(e) => setTagFilterDropdownOpen(!tagFilterDropdownOpen)}
        />
      ),
    },
    {
      title: "Author",
      key: "user_email",
      dataIndex: "user_email",
      ellipsis: true,
      filters: authorList.map((value) => ({
        text: value,
        value: value,
      })),
      render: (_, { user_email }) => (
        <>
          <div
            style={{
              margin: "15px",
              display: "flex",
              gap: 5,
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#ff6000",
                verticalAlign: "middle",
              }}
              size="default"
            >
              {user_email.charAt(0).toUpperCase()}
            </Avatar>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                flex: 1,
              }}
            >
              {user_email}
            </div>
          </div>
        </>
      ),
      filterDropdown: (
        <div style={{ display: "flex", flexDirection: "column", padding: 8 }}>
          <Checkbox.Group
            options={authorList}
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              maxHeight: "200px", // Set a maximum height
              overflowY: "auto",
            }}
            value={selectedAuthors}
            onChange={(checkedValues: any) => {
              setSelectedAuthors(checkedValues);
            }}
          />
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="small"
              type="default"
              onClick={(e) => setSelectedAuthors([])}
            >
              Reset
            </Button>
            <Button size="small" type="primary" onClick={getFilteredData}>
              OK
            </Button>
          </div>
        </div>
      ),
      filterDropdownOpen: authorFilterDropdownOpen,
      filterIcon: (
        <FilterTwoTone
          twoToneColor={!selectedAuthors.length ? "grey" : "#1890ff"}
          onClick={(e) =>
            setAuthorFilterDropdownOpen(!authorFilterDropdownOpen)
          }
        />
      ),
    },
    {
      title: "View Iframe",
      dataIndex: "interact",
      key: "interact",
      render: (text, { id }) => (
        <>
          <a
            onClick={() => {
              setModalId(id);
            }}
          >
            Link
          </a>
        </>
      ),
    },
  ];
  const handleOk = () => {
    setModalId("");
  };

  const handleCancel = () => {
    setModalId("");
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<{
    id: string;
    title: string;
    connection_config_name: string;
    user_email: string;
    tags: Array<string>;
  }> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const downloadJSON = () => {
    const jsonData = JSON.stringify(
      widgetList
        .filter((row: any) => selectedRowKeys.includes(row.id))
        .map((row: any) => ({
          ...row,
          "iframe-url": `${baseUiUrl}/live-widget/${row.id}/`,
        })),
      null,
      2
    ); // Convert array to formatted JSON string
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create a link element and click it programmatically to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ height: "80px" }}>
      <Modal
        title="Iframe View"
        open={modalId ? true : false}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80vw"
        style={{ top: 20 }}
      >
        <iframe
          style={{ height: "80vh", width: "100%" }}
          src={`${baseUiUrl}/live-widget/${modalId}`}
        />
      </Modal>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          <h2 style={{ marginTop: "0" }}>Widgets</h2>
        </div>
        <Button
          onClick={downloadJSON}
          type="primary"
          style={{ marginBottom: 16 }}
          disabled={selectedRowKeys.length < 1}
          icon={<DownloadOutlined />}
        >
          Download({selectedRowKeys.length} Rows)
        </Button>
      </div>

      <br />
      <Table
        // rowSelection={rowSelection}
        scroll={{ y: "70vh" }}
        columns={columns}
        dataSource={widgetList.map((row: any) => ({ ...row, key: row.id }))}
        loading={isWidgetListLoading}
        pagination={{
          current: currentPage,
          pageSize: itemsPerPage,
          total: totalCount,
          onChange: (page, pageSize) => {
            fetchPage(page, pageSize);
          },
        }}
      />
    </div>
  );
};
