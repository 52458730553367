import { useLocation, useParams } from "react-router-dom";
import { DBConfig, NotificationType } from "./DBConfig";
import { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../NewConnection";
import { CustomInput } from "../../utils/CustomeInput";
import { Button, Segmented, Spin, notification } from "antd";
import {
  GoogleSheetConfig,
  IGoogleSheetConfigs,
  ISheetDbTableMap,
} from "./GoogleSheetConfig";
import { DataView } from "./DataView";

export interface IConnectionDbColumnConfig {
  name: string;
  type: string;
  foreign_key: string;
  is_primary_key: boolean;
}

enum Tabs {
  GOOGLE_SHEET = "Google Sheet",
  DATABASE = "Database Structure",
  Data = "Data",
}

export const DatabaseDetail = () => {
  const { state } = useLocation();
  const { connectionId } = useParams();
  const [connectionName, setConnectionName] = useState<string>(
    state?.name ? state.name : ""
  );
  const [connectionDbConfig, setConnectionDbConfig] = useState<{
    [key: string]: Array<IConnectionDbColumnConfig>;
  }>(state?.db_config ? state.db_config : {});

  const [disabledTables, setDisabledTables] = useState<Array<string>>([]);
  const [dbSummaryJson, setDbSummaryJson] = useState<{
    [key: string]: { description: string };
  }>({});
  const [isConnectionNameUpdating, setIsConnectionNameUpdating] =
    useState<boolean>(false);
  const [isConnectionConfigLoading, setIsConnectionConfigLoading] = useState(
    connectionName ? false : true
  );
  const [api, contextHolder] = notification.useNotification();
  const [selectedSegment, setSelectedSegment] = useState<Tabs>();
  const [googleSheetConfigs, setGoogleSheetConfigs] = useState<
    Array<IGoogleSheetConfigs>
  >(state?.gsheet_config ? state.gsheet_config : null);

  const [selectedTab, setSelectedTab] = useState<string>(
    state?.gsheet_config && state?.gsheet_config.length > 0
      ? Tabs.GOOGLE_SHEET
      : Tabs.DATABASE
  );
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    if (!connectionName) {
      axios
        .get(`${baseUrl}/connection/${connectionId}/`)
        .then(function (response) {
          setConnectionName(response.data.name);
          setConnectionDbConfig(response.data.db_config);
          setDisabledTables(response.data.disabled_tables);
          setGoogleSheetConfigs(response.data.gsheet_configs);
          setDbSummaryJson(response.data.db_summary_json);
          setIsConnectionConfigLoading(false);
          setSelectedTab(
            response.data.gsheet_configs &&
              response.data.gsheet_configs.length > 0
              ? Tabs.GOOGLE_SHEET
              : Tabs.DATABASE
          );
        })
        .catch(function (error) {
          console.log(error.response.data.error);
          setIsConnectionConfigLoading(false);
        });
    }
  }, []);

  const updateConnectionName = (name: string) => {
    setIsConnectionNameUpdating(true);
    axios
      .put(`${baseUrl}/connection/${connectionId}/update-name/`, { name: name })
      .then(function (response) {
        setConnectionName(name);
        setIsConnectionNameUpdating(false);
        openNotificationWithIcon("success", response.data.message);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsConnectionNameUpdating(false);
        openNotificationWithIcon("error", error.response.data.error);
      });
  };

  const SectionElementMap: { [key: string]: ReactNode } = {
    [Tabs.GOOGLE_SHEET]: (
      <GoogleSheetConfig
        googleSheetConfigs={googleSheetConfigs}
        setGoogleSheetConfigs={setGoogleSheetConfigs}
        connectionDbConfig={connectionDbConfig}
        setConnectionDbConfig={setConnectionDbConfig}
      />
    ),
    [Tabs.DATABASE]: (
      <DBConfig
        connectionName={connectionName}
        connectionId={connectionId!}
        connectionDbConfig={connectionDbConfig}
        isConnectionConfigLoading={isConnectionConfigLoading}
        setConnectionDbConfig={setConnectionDbConfig}
        disabledTables={disabledTables}
        setDisabledTables={setDisabledTables}
        dbSummaryJson={dbSummaryJson}
        setDbSummaryJson={setDbSummaryJson}
      />
    ),
    [Tabs.Data]: <DataView connectionDbConfig={connectionDbConfig} />,
  };
  return (
    <>
      {contextHolder}
      {
        <>
          {isConnectionConfigLoading ? (
            <div
              style={{
                display: "flex",
                height: "-webkit-fill-available",
                justifyContent: "center",
              }}
            >
              <Spin style={{ alignSelf: "center" }} />
            </div>
          ) : (
            <>
              <div>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <CustomInput
                      value={connectionName}
                      isUpdating={isConnectionNameUpdating}
                      updateValue={updateConnectionName}
                    />
                  </div>
                  <Segmented
                    value={selectedTab}
                    options={
                      googleSheetConfigs && googleSheetConfigs.length > 0
                        ? [Tabs.GOOGLE_SHEET, Tabs.DATABASE, Tabs.Data]
                        : [Tabs.DATABASE, Tabs.Data]
                    }
                    onChange={(v) => {
                      setSelectedTab(v as Tabs);
                    }}
                  />
                </div>
              </div>
              <br />
              {selectedTab && SectionElementMap[selectedTab]}
            </>
          )}
        </>
      }
    </>
  );
};
