import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "./database/NewConnection";
import { Spin } from "antd";
import { WidgetPreview } from "./interact/components/WidgetPreview";

export const LiveWidget = () => {
  const { widgetId } = useParams();
  const [isWidgetLoading, setIsWidgetLoading] = useState<boolean>(true);
  const [widgetData, setWidgetData] = useState<any>({});
  useEffect(() => {
    console.log("first")
    axios
      .get(`${baseUrl}/chat/v3/widget/${widgetId}/`)
      .then(function (response) {
        setWidgetData(response.data);
        setIsWidgetLoading(false);
      })
      .catch(function (e) {
        setIsWidgetLoading(false);
      });
  }, []);
  return (
    <>
      {isWidgetLoading || !widgetData ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <div style={{ padding: "10px" }}>
            <WidgetPreview
              data={widgetData.data}
              widgetConfig={widgetData.widgetConfig}
              title={widgetData.title}
            />
          </div>
        </>
      )}
    </>
  );
};
