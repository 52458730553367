import {
  CheckOutlined,
  CloseOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  HomeOutlined,
  LoadingOutlined,
  MessageOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  StepBackwardOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Modal,
  Select,
  Spin,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import type { MenuProps } from "antd"; for ts
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import MenuItem from "antd/es/menu/MenuItem";
import axios from "axios";
import { baseUrl } from "../../database/NewConnection";
import GlossaryIcon from "../../../assets/glossary.svg";
import BackIcon from "../../../assets/back.svg";
import CrossIcon from "../../../assets/cross.svg";
import PlusIcon from "../../../assets/plusCircled.svg";
import DownIcon from "../../../assets/down.svg";
import UpIcon from "../../../assets/up.svg";
import InfiniteScroll from "react-infinite-scroll-component";

export const SideSegment = (props) => {
  const {
    connectionConfigId,
    chatId,
    setQuestionAnswerList,
    controllerRef,
    chatList,
    connectionList,
    isChatListLoading,
    isConnectionListLoading,
    setChatList,
    next,
    setNext,
    totalChatListLength,
    setTotalChatListLength,
  } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewKey, setIsNewKey] = useState(false);
  const [saving, setIsSaving] = useState(false);
  const [keywords, setKeywords] = useState(null);
  const [keywordsLoading, setIsKeywordsLoading] = useState(false);
  const [newKey, setNewKey] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [updating, setIsUpdating] = useState(false);
  const [deleting, setIsDeleting] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [editingTitle, setEditingTitle] = useState(false);
  const [editTitleMode, setEditingTitleMode] = useState(false);
  const [deletingChat, setDeletingChat] = useState(false);
  const [editedTitle, setEditedTitle] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const loadMore = () => {
    if (loadingMore || !next) {
      return;
    }
    setLoadingMore(true);
    axios
      .get(next)
      .then((response) => {
        setNext(response.data.next);
        setChatList([...chatList, ...response.data.results]);
        setLoadingMore(false);
        setTotalChatListLength(response.data.count);
      })
      .catch((error) => {
        setLoadingMore(false);
      });
  };
  const handleEditTitle = (id) => {
    setEditingTitle(true);
    axios
      .put(`${baseUrl}/chat/v3/chats/${id}/update-name/`, {
        title: editedTitle,
      })
      .then((response) => {
        setChatList((prevChatList) => {
          return prevChatList.map((chat) => {
            if (chat.id === chatId) {
              return { ...chat, title: editedTitle };
            }
            return chat;
          });
        });
        setEditingTitle(false);
        setEditingTitleMode(false);
      })
      .catch((err) => {
        console.log(err);
        setEditingTitle(false);
        setEditingTitleMode(false);
      });
  };
  const handleDelete = (id) => {
    setDeletingChat(true);
    axios
      .put(`${baseUrl}/chat/v3/chats/${id}/delete/`)
      .then((response) => {
        setChatList((prevChatList) => {
          return prevChatList.filter((chat) => chat.id !== chatId);
        });
        setDeletingChat(false);
        setIsDeleteModalVisible(false);
        setOpen(false);
        // setIsArchived(true);
        navigate(`/interact/${connectionConfigId}`);
        controllerRef.current.abort(); // Cancel previous request
        setQuestionAnswerList([]);
        // setIsArchived(false);
      })
      .catch((err) => {
        setDeletingChat(false);
        setOpen(false);
        console.log(err);
      });
  };
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
      placement: "bottomRight",
    });
  };

  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    if (!keys.includes("root")) {
      setOpenKeys([]);
      return;
    }
    if (keys.length == 3) {
      setOpenKeys([keys[0], keys[2]]);
      return;
    }
    setOpenKeys(keys);
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const handleUpdate = () => {
    var selectedkey = keywords.filter((key) => {
      return key.id === openKeys[openKeys.length - 1];
    })[0];
    setNewKey(selectedkey.key);
    setNewDesc(selectedkey.description);
    setIsEditing(true);
  };

  const items = [
    getItem(
      <>
        {/* <style>{customScrollbarStyles}</style> */}
        <div
          style={{
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: 600,
            width: "100%",
            padding: 0,
          }}
        >
          Saved Keywords
        </div>
      </>,
      "root",
      null,
      !keywordsLoading
        ? !keywords?.length
          ? [getItem("You Dont have any keywords Yet!", "M")]
          : keywords?.map(
              (keyword) =>
                !keyword.archived &&
                getItem(<div>{keyword.key}</div>, keyword.id, null, [
                  getItem(
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "80%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {keyword.description}
                      </div>
                      <div>
                        <EditOutlined onClick={handleUpdate} />
                      </div>
                    </div>,
                    keyword.description
                  ),
                ])
            )
        : [getItem(<Spin />, "Spin")]
    ),
  ];

  useEffect(() => {
    setEditingTitleMode(false);
    setOpen(false);
  }, [chatId]);

  useEffect(() => {
    if (connectionConfigId) {
      setIsKeywordsLoading(true);
      axios
        .get(`${baseUrl}/connection/${connectionConfigId}/keywords/`)
        .then((response) => {
          setIsKeywordsLoading(false);
          setKeywords(response.data);
        })
        .catch((err) => {
          console.log(err);
          setIsKeywordsLoading(false);
        });
    }
  }, [connectionConfigId]);

  const showModal = () => {
    setIsModalVisible(true);
    if (keywords === null) {
      setIsKeywordsLoading(true);
      axios
        .get(`${baseUrl}/connection/${connectionConfigId}/keywords/`)
        .then((response) => {
          setIsKeywordsLoading(false);
          setKeywords(response.data);
        })
        .catch((err) => {
          console.log(err);
          setIsKeywordsLoading(false);
        });
    }
  };

  const handleSave = () => {
    if (isNewKey && !saving) {
      setIsSaving(true);
      axios
        .put(
          `${baseUrl}/connection/${connectionConfigId}/update-db-keywords/`,
          {
            keywords: [
              {
                key: newKey,
                description: newDesc,
              },
            ],
          }
        )
        .then((response) => {
          setKeywords(response.data.keywords);
          setIsNewKey(false);
          setIsSaving(false);
          setNewDesc("");
          setNewKey("");
          openNotificationWithIcon(
            "success",
            "Successfully update glossary",
            "Your keywords have been saved. Your team can make edits."
          );
        })
        .catch((err) => {
          openNotificationWithIcon(
            "error",
            "Error",
            err.response.data.error || err.message
          );
          console.log(err.response.data.error || err.message);
          setIsSaving(false);
        });
    }
    if (isEditing && !updating) {
      setIsUpdating(true);
      axios
        .put(`${baseUrl}/connection/${connectionConfigId}/update-keyword/`, {
          id: openKeys[openKeys.length - 1],
          key: newKey,
          description: newDesc,
          archived: false,
        })
        .then((response) => {
          setIsUpdating(false);
          setIsEditing(false);
          setNewDesc("");
          setNewKey("");
          setKeywords(response.data.keywords);
          openNotificationWithIcon(
            "success",
            "Successfully update glossary",
            "Your keywords have been saved. Your team can make edits."
          );
        })
        .catch((err) => {
          openNotificationWithIcon(
            "error",
            "Error",
            err.response.data.error || err.message
          );
          console.log(err.response.data.error || err.message);
          setIsUpdating(false);
        });
    }
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Poppins, sans-serif",
          height: "100%",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div>
            <span>Connection:</span>
            <Select
              value={isConnectionListLoading ? "" : connectionConfigId}
              loading={isConnectionListLoading}
              style={{ width: "100%", marginTop: "5px" }}
              onChange={(e) => {
                navigate(`/interact/${e}`);
              }}
              options={connectionList.map((row) => ({
                value: row.id,
                label: row.name,
              }))}
            />
          </div>
          {connectionConfigId && (
            <>
              <div style={{ margin: "0px 8px" }}>
                <Divider style={{ margin: "0px" }} />
              </div>
              <Button
                onClick={() => {
                  controllerRef.current.abort();
                  setQuestionAnswerList([]);
                  navigate(`/interact/${connectionConfigId}`);
                }}
                icon={<PlusCircleOutlined />}
                style={{
                  width: "100%",
                  textAlignLast: "justify",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                New Chat
              </Button>
            </>
          )}
        </div>
        {connectionConfigId && (
          <>
            <div
              className="custom-scrollbar"
              id="scrollableDiv"
              style={{
                marginRight: "10px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 10,
                flex: 1,
                overflow: "scroll",
              }}
            >
              {isChatListLoading ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  >
                    <Spin />
                  </div>
                </>
              ) : (
                <InfiniteScroll
                  dataLength={chatList.length}
                  next={loadMore}
                  hasMore={chatList.length < totalChatListLength}
                  loader={
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Spin />
                    </div>
                  }
                  endMessage={null}
                  scrollableTarget="scrollableDiv"
                  style={{ overflow: "hidden" }}
                >
                  {chatList.map(
                    (row) =>
                      !row.isArchived && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/interact/${connectionConfigId}/${row.id}`
                            );
                          }}
                          icon={<MessageOutlined />}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            textAlign: "left",
                            fontFamily: "Poppins, sans-serif",
                            borderColor: row.id === chatId && " #1890ff",
                            color: row.id === chatId && "#1890ff",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              flex: "1",
                            }}
                          >
                            {editTitleMode && row.id === chatId ? (
                              <Input
                                style={{
                                  background: "inherit",
                                  height: "70%",
                                  font: "inherit",
                                  padding: 0,
                                }}
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                onPressEnter={(e) => handleEditTitle(row.id)}
                              />
                            ) : (
                              <>{row.title}</>
                            )}
                          </div>
                          <div style={{ justifySelf: "flex-end" }}>
                            {row.id === chatId ? (
                              editTitleMode ? (
                                editingTitle ? (
                                  <LoadingOutlined />
                                ) : (
                                  <div style={{ display: "flex", gap: 5 }}>
                                    <CheckOutlined
                                      onClick={(e) => {
                                        handleEditTitle(row.id);
                                      }}
                                    />
                                    <CloseOutlined
                                      onClick={(e) =>
                                        setEditingTitleMode(false)
                                      }
                                    />
                                  </div>
                                )
                              ) : (
                                <div style={{ display: "flex", gap: 5 }}>
                                  <EditOutlined
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditingTitleMode(true);
                                      setEditedTitle(row.title);
                                    }}
                                  />
                                  <DeleteOutlined
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpen(true);
                                    }}
                                  />
                                  <Modal
                                    centered
                                    title="Delete Chat?"
                                    open={open}
                                    onOk={(e) => {
                                      e.preventDefault();
                                      handleDelete(chatId);
                                    }}
                                    confirmLoading={deletingChat}
                                    onCancel={(e) => setOpen(false)}
                                  >
                                    <p>
                                      This Will Delete <b>"{`${row.title}`}"</b>
                                    </p>
                                  </Modal>
                                </div>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </Button>
                      )
                  )}
                </InfiniteScroll>
              )}
            </div>
            <br />
            <div
              style={{
                height: "10%",
                backgroundColor: "#5E5ADB",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "0 10px",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0,
                  width: "80%",
                }}
              >
                <div style={{ fontSize: "13px", color: "rgba(255,255,255)" }}>
                  Glossary
                </div>
                <div
                  style={{ fontSize: "11.5px", color: "rgba(255,255,255,0.8)" }}
                >
                  Add New Keywords for better responses
                </div>
              </div>
              <div>
                <Button
                  icon={<PlusOutlined />}
                  style={{
                    background: "rgba(255,255,255,0.2)",
                    border: "none",
                    borderRadius: "50%",
                    color: "white",
                  }}
                  onClick={showModal}
                />
                <Modal
                  open={isModalVisible}
                  closeIcon={null}
                  centered
                  onCancel={(e) => setIsModalVisible(false)}
                  maskClosable={true}
                  width={450}
                  style={{ padding: "24px" }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontFamily: "Poppins",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: isEditing || isNewKey ? "100%" : "",
                          alignSelf: "flex-end",
                        }}
                      >
                        {(isEditing || isNewKey) && (
                          <img
                            src={BackIcon}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              setIsEditing(false);
                              setIsNewKey(false);
                              setNewDesc("");
                              setNewKey("");
                            }}
                          />
                        )}
                        <img
                          src={CrossIcon}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => setIsModalVisible(false)}
                        />
                      </div>
                      <Button
                        icon={<img src={GlossaryIcon} alt={"icon"} />}
                        style={{
                          border: "none",
                          borderRadius: "10px",
                          width: "48px",
                          height: "48px",
                          cursor: "default",
                          transition: "none",
                          backgroundColor: "transparent",
                          color: "black",
                          margin: "15px 0",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        Glossary
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        Manage the glossary of terms of your Database.
                      </div>
                    </div>
                  }
                  footer={null} // Set the footer to null to remove OK and Cancel buttons
                >
                  {isNewKey || isEditing ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        gap: "20px",
                        fontWeight: 600,
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <div style={{ marginBottom: "10px" }}>Name</div>
                        <Input
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            padding: "10px",
                          }}
                          placeholder="Enter term name.."
                          value={newKey}
                          onChange={(e) => setNewKey(e.target.value)}
                        />
                      </div>
                      <div style={{ width: "90%" }}>
                        <div style={{ marginBottom: "10px" }}>Definition</div>
                        <Input.TextArea
                          rows={3}
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            gap: 10,
                            fontWeight: 500,
                            padding: "10px",
                          }}
                          placeholder="Write a brief introduction.."
                          value={newDesc}
                          onChange={(e) => setNewDesc(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "90%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontWeight: 500,
                        }}
                      >
                        <div
                          style={{
                            width: "46%",
                            cursor: "pointer",
                            borderRadius: "8px",
                            padding: "10px 0",
                            border: "2px solid #D0D5DD",
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            if (isNewKey) {
                              setIsNewKey(false);
                              setNewDesc("");
                              setNewKey("");
                            }
                            if (isEditing) {
                              setIsDeleteModalVisible(true);
                            }
                          }}
                        >
                          {isNewKey && "Cancel"}
                          {isEditing && "Delete"}
                        </div>
                        <div
                          style={{
                            width: "46%",
                            cursor: "pointer",
                            borderRadius: "8px",
                            padding: "10px 0",
                            border: "none",
                            background: !(saving || updating)
                              ? "#5E5ADB"
                              : "#5E5ADB80",
                            textAlign: "center",
                            alignSelf: "center",
                            color: "white",
                          }}
                          onClick={handleSave}
                        >
                          {isNewKey && (!saving ? "Save" : "Saving..")}
                          {isEditing && (!updating ? "Update" : "Updating..")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          background: "#5E5ADB",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "12px 16px 12px 29px",
                          flexDirection: "row",
                          cursor: "pointer",
                          width: "85%",
                          borderRadius: "10px",
                          fontSize: "16px",
                          marginBottom: "20px",
                          transition: "max-height 2s ease-in-out",
                          marginTop: "20px",
                        }}
                        onClick={(e) => setIsNewKey(true)}
                      >
                        <div>Add Keyword</div>
                        <img src={PlusIcon} />
                      </div>
                      <Menu
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        style={{
                          width: "97%",
                          overflow: "auto",
                          maxHeight: "300px",
                          boxShadow:
                            "0px 0.76271px 2.28813px 0px rgba(18, 18, 18, 0.10), 0px 0px 0px 0.76271px rgba(18, 18, 18, 0.20)",
                          borderRadius: "10px",
                          marginBottom: "12px",
                          padding: 0,
                        }}
                        className="custom-scrollbar"
                        items={items}
                      />
                    </div>
                  )}
                  <Modal
                    closeIcon={
                      <CloseOutlined
                        style={{ cursor: "pointer" }}
                        onClick={(e) => setIsDeleteModalVisible(false)}
                      />
                    }
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          fontFamily: "Poppins",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            alignSelf: "flex-start",
                          }}
                        >
                          Delete Keyword
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Are you sure you want to delete this keyword?
                        </div>
                      </div>
                    }
                    open={isDeleteModalVisible}
                    footer={null} // Set the footer to null to remove OK and Cancel buttons
                    centered
                    width={370}
                    style={{ padding: "40px 0" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        gap: "20px",
                        fontWeight: 500,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "45%",
                            cursor: "pointer",
                            borderRadius: "8px",
                            padding: "10px 0",
                            border: "2px solid #D0D5DD",
                            textAlign: "center",
                            alignSelf: "center",
                          }}
                          onClick={(e) => {
                            if (isEditing) {
                              setIsDeleteModalVisible(false);
                            }
                          }}
                        >
                          {isEditing && "Cancel"}
                        </div>
                        <div
                          style={{
                            width: "45%",
                            cursor: "pointer",
                            borderRadius: "8px",
                            padding: "10px 0",
                            border: "none",
                            background: !deleting
                              ? "#D92D20"
                              : "rgba(255,0,0,0.3)",
                            textAlign: "center",
                            color: "white",
                            alignSelf: "center",
                          }}
                          onClick={(e) => {
                            if (!deleting) {
                              setIsDeleting(true);
                              axios
                                .put(
                                  `${baseUrl}/connection/${connectionConfigId}/delete-keyword/`,
                                  { id: openKeys[openKeys.length - 1] }
                                )
                                .then((response) => {
                                  setIsEditing(false);
                                  setIsDeleting(false);
                                  setIsDeleteModalVisible(false);
                                  setKeywords(response.data);
                                  openNotificationWithIcon(
                                    "success",
                                    "Keyword deleted successfully",
                                    "Your keyword has been deleted"
                                  );
                                })
                                .catch((err) => {
                                  console.log(
                                    err.response.data.error || err.message
                                  );
                                  openNotificationWithIcon(
                                    "error",
                                    "Error",
                                    err.response.data.error || err.message
                                  );
                                  setIsDeleting(false);
                                  setIsDeleteModalVisible(false);
                                });
                            }
                          }}
                        >
                          {isEditing && (!deleting ? "Delete" : "Deleting..")}
                        </div>
                      </div>
                    </div>
                  </Modal>
                </Modal>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
