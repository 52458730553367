import { Button, Input, Modal, Select, notification } from "antd";
import { useParams } from "react-router-dom";
import { NotificationType } from "./DBConfig";
import { useState } from "react";
import {
  ECreateTableValueTypes,
  ICreateTableConfig,
} from "./CreateDBTableModal";
import { IConnectionDbColumnConfig } from "./DatabaseDetail";
import axios from "axios";
import { baseUrl } from "../NewConnection";

interface IUpdateDBTableModalProps {
  openUpdateGoogleSheetModal: boolean;
  setOpenUpdateGoogleSheetModal: (openUpdateGoogleSheetModal: boolean) => void;
  connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  };
  setConnectionDbConfig: (connectionDbConfig: {
    [key: string]: Array<IConnectionDbColumnConfig>;
  }) => void;
}

export const UpdateDBTableModal = (props: IUpdateDBTableModalProps) => {
  const { connectionId } = useParams();
  const {
    openUpdateGoogleSheetModal,
    setOpenUpdateGoogleSheetModal,
    connectionDbConfig,
    setConnectionDbConfig,
  } = props;
  const [updateTableConfig, setUpdateTableConfig] =
    useState<ICreateTableConfig>({
      tableName: "",
      values: [],
    });
  const [isUpdateDbCreateLoading, setIsUpdateDbCreateLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
      placement: "bottomRight",
    });
  };

  const addColumn = () => {
    const tmpUpdateTableConfig = { ...updateTableConfig };
    tmpUpdateTableConfig.values.push({
      columnName: "",
    });
    setUpdateTableConfig(tmpUpdateTableConfig);
  };

  const handleSelectTableChange = (value: string) => {
    const tmpCreateTableConfig = { ...updateTableConfig };
    tmpCreateTableConfig.tableName = value;
    setUpdateTableConfig(tmpCreateTableConfig);
  };

  const handleTypeChange = (index: number, value: ECreateTableValueTypes) => {
    const tmpCreateTableConfig = { ...updateTableConfig };
    tmpCreateTableConfig.values[index].type = value;
    setUpdateTableConfig(tmpCreateTableConfig);
  };

  const handleColumnNameChange = (index: number, value: string) => {
    const tmpCreateTableConfig = { ...updateTableConfig };
    tmpCreateTableConfig.values[index].columnName = value;
    setUpdateTableConfig(tmpCreateTableConfig);
  };

  const updateDBTable = () => {
    setIsUpdateDbCreateLoading(true);
    axios
      .put(
        `${baseUrl}/connection/${connectionId}/gsheet/new-table/`!,
        updateTableConfig
      )
      .then(function (response) {
        console.log(response);
        setConnectionDbConfig(response.data.db_config);
        setIsUpdateDbCreateLoading(false);
        setOpenUpdateGoogleSheetModal(false);
        openNotificationWithIcon("success", "Table Updated!");
        //   setIsNewConnectionLoading(false);
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        setIsUpdateDbCreateLoading(false);
        openNotificationWithIcon("error", error.response.data.error);
        //   setIsNewConnectionLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={openUpdateGoogleSheetModal}
        centered
        width={"60vw"}
        title="Create New DB Table"
        // onOk={handleOk}
        onCancel={() => {
          setOpenUpdateGoogleSheetModal(false);
        }}
        footer={[
          <Button
            type="primary"
            key="back"
            onClick={updateDBTable}
            loading={isUpdateDbCreateLoading}
          >
            Update DB Table
          </Button>,
        ]}
      >
        <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <p style={{ marginBottom: "5px" }}>Select Table</p>
          <Select
            placeholder="Select Sheet Tabe"
            style={{ width: "100%" }}
            onChange={(e) => handleSelectTableChange(e)}
            options={
              connectionDbConfig
                ? Object.keys(connectionDbConfig)?.map((row, index) => ({
                    value: row,
                    label: <>{row}</>,
                  }))
                : []
            }
          />
          <br />
          {updateTableConfig.tableName &&
            connectionDbConfig[updateTableConfig.tableName].map(
              (row, index) => (
                <>
                  <div style={{ display: "flex", gap: 10 }}>
                    <div style={{ flex: 1 }}>
                      <p style={{ marginBottom: "5px" }}>Column Name</p>
                      <Input disabled value={row.name} />
                    </div>
                    <div style={{ flex: 1 }}>
                      <p style={{ marginBottom: "5px" }}>Type</p>
                      <Select
                        key={index}
                        placeholder="Select column type"
                        style={{ width: "100%" }}
                        value={row.type}
                        disabled
                      />
                    </div>
                  </div>
                </>
              )
            )}
          {updateTableConfig.values.map((row, index) => (
            <>
              <div style={{ display: "flex", gap: 10 }}>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: "5px" }}>Column Name</p>
                  <Input
                    value={row.columnName}
                    onChange={(e) =>
                      handleColumnNameChange(index, e.target.value)
                    }
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: "5px" }}>Type</p>
                  <Select
                    key={index}
                    placeholder="Select column type"
                    style={{ width: "100%" }}
                    value={row.type}
                    onChange={(value) => {
                      handleTypeChange(index, value);
                    }}
                    options={(
                      Object.keys(ECreateTableValueTypes) as Array<
                        keyof typeof ECreateTableValueTypes
                      >
                    ).map((key) => ({
                      value: ECreateTableValueTypes[key],
                      label: key.charAt(0) + key.slice(1).toLowerCase(), // Convert key to label format
                    }))}
                  />
                </div>
              </div>
            </>
          ))}
          <div>
            <br />
            <Button onClick={addColumn}>+ Add Column</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
