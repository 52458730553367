import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Input, Select, Spin, Table } from "antd";
import { IQuestionAnswerPropsV2 } from "../Interact";
import { Slider } from "./Slider";
import { configToUse } from "../../../Keycloak";
import { Chart, formatString } from "./Chart";
import { BulbOutlined } from "@ant-design/icons";
import { ExportWidget } from "./ExportWidget";
import { Feedback } from "./Feedback";
import CustomLoader from "../../utils/CustomLoader";

const ChatV2 = (props: {
  questionAnswerListV2: IQuestionAnswerPropsV2[];
  setQuestionAnswerListV2: React.Dispatch<
    React.SetStateAction<IQuestionAnswerPropsV2[]>
  >;
  getData: any;
  callExampleQuestion: any;
  sampleQuestionList: any;
}) => {
  const {
    questionAnswerListV2,
    setQuestionAnswerListV2,
    getData,
    callExampleQuestion,
    sampleQuestionList,
  } = props;
  const [customPrompt, setcustomPrompt] = useState<string>("");
  console.log(questionAnswerListV2);
  const isChartExist = (row: IQuestionAnswerPropsV2) => {
    return (
      row.chartSelection === "BarChart" ||
      row.chartSelection === "LineChart" ||
      row.chartSelection === "PieChart" ||
      row.chartSelection === "MultiLineChart"
    );
  };
  const updateQuestionGraph = (questionId: string, graphList: any) => {
    const updatedList = [...questionAnswerListV2];
    updatedList.filter(
      (questionAnswer) => questionAnswer.questionId === questionId
    )[0].graphs = graphList;
    setQuestionAnswerListV2(updatedList);
  };

  return (
    <>
      {questionAnswerListV2.length === 0 && (
        <div
          style={{
            alignItems: "center",
            height: "-webkit-fill-available",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            fontSize: "20px",
            gap: 15,
          }}
        >
          {sampleQuestionList ? (
            <>
              <BulbOutlined style={{ fontSize: "30px" }} />
              <div style={{ margin: "2px 0 10px 0" }}>Examples</div>
              {sampleQuestionList.map((row: any) => (
                <>
                  <Button
                    onClick={() => {
                      callExampleQuestion(row);
                    }}
                    style={{
                      width: "420px",
                      height: "auto",
                      padding: "12px",
                      fontSize: "15px",
                      borderRadius: "10px",
                      whiteSpace: "normal",
                    }}
                  >
                    {`"${row}" `} →
                  </Button>
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      )}

      {questionAnswerListV2.map((row, index) => (
        <div key={index}>
          <div style={{ display: "flex", gap: 20, padding: "20px" }}>
            <div>
              <Avatar
                style={{
                  backgroundColor: "#ff6000",
                  verticalAlign: "middle",
                  fontSize: "12px",
                }}
                gap={1}
              >
                {row?.user?.charAt(0)?.toUpperCase()}
              </Avatar>
            </div>
            <div style={{ width: "90%", alignSelf: "center" }}>
              {row.question}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 20,
              padding: "20px",
              borderRadius: "16px",
              backgroundColor: "#fff",
            }}
          >
            <div>
              <Avatar
                src={
                  <img
                    src={configToUse.REACT_APP_CHAT_LOGO}
                    alt="avatar"
                    style={{ width: "auto", padding: "7px" }}
                  />
                }
                style={{ verticalAlign: "middle" }}
                gap={1}
              />
            </div>
            {row.isLoading ? (
              <CustomLoader
                isLoading={row.isLoading}
                text={
                  questionAnswerListV2[index - 1]?.type === "completion" ||
                  index === 0 ||
                  questionAnswerListV2[index - 1].type === "query"
                    ? "Understanding your question"
                    : questionAnswerListV2[index - 1].type ===
                      "final_confirmation"
                    ? "Fetching the data"
                    : "Analysing the information you provided"
                }
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                }}
              >
                {/* <CustomLoader
                  isLoading={true}
                  text={"Fetching the data"}
                /> */}
                {row.assumptions && row.assumptions.length > 0 && (
                  <>
                    <strong>Assumptions:</strong>
                    <Divider />
                  </>
                )}
                {row.type === "completion" && (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          row.answer +
                          ""
                            .replace(/\n/g, "<br />")
                            .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                      }}
                    ></div>
                  </>
                )}
                {row.clarificationData && row.type === "clarification" && (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          row.clarificationData?.question +
                          ""
                            .replace(/\n/g, "<br />")
                            .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-end",
                        // justifyContent: "space-around",
                        gap: 10,
                      }}
                    >
                      {row.clarificationData.options.map(
                        (option: string, i: number) => (
                          <Button
                            key={i}
                            type={option === "Other" ? "default" : "primary"}
                            onClick={(e) => {
                              e.preventDefault();
                              const updatedList = [...questionAnswerListV2];
                              updatedList[index].clarificationData.options = [];
                              setQuestionAnswerListV2(updatedList);
                              getData(option);
                            }}
                          >
                            {option}
                          </Button> // Wrapped each option in a <span> and added a key
                        )
                      )}
                    </div>
                    {row.clarificationData.options.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "space-evenly",
                        }}
                      >
                        {/* <Input
                          placeholder="Send a message..."
                          onPressEnter={() => {
                            if (customPrompt) {
                              const updatedList = [...questionAnswerListV2];
                              updatedList[index].clarificationData.options = [];
                              setQuestionAnswerListV2(updatedList);
                              getData(customPrompt);
                              setcustomPrompt("");
                            }
                          }}
                          value={customPrompt}
                          onChange={(e) => {
                            setcustomPrompt(e.target.value);
                          }}
                          style={{
                            padding: "7px 17px",
                            borderRadius: "100px",
                            fontSize: "12px",
                            fontFamily: "Poppins, sans-serif",
                            width: "65%",
                          }}
                        /> */}
                      </div>
                    )}
                  </>
                )}
                {row.finalConfirmationData &&
                  row.type === "final_confirmation" && (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            "Just to confirm, here is a summary of our conversation:"
                              .replace(/\n/g, "<br />")
                              .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            "<b>" +
                            row.finalConfirmationData?.redefined_input +
                            "</b>"
                              .replace(/\n/g, "<br />")
                              .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: "Does this looks good?"
                            .replace(/\n/g, "<br />")
                            .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                        }}
                      ></div>
                      {!row.finalConfirmationData?.confirmed ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "flex-end",
                              // justifyContent: "space-around",
                              gap: 10,
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={(e) => {
                                e.preventDefault();
                                const updatedList = [...questionAnswerListV2];
                                updatedList[
                                  index
                                ].finalConfirmationData.confirmed = true;
                                setQuestionAnswerListV2(updatedList);
                                getData("Yes");
                              }}
                            >
                              {"Yes, show me the answer"}
                            </Button>
                            <Button
                              type="default"
                              onClick={(e) => {
                                e.preventDefault();
                                const updatedList = [...questionAnswerListV2];
                                updatedList[
                                  index
                                ].finalConfirmationData.confirmed = true;
                                setQuestionAnswerListV2(updatedList);
                                getData("No, this isn't quite right");
                              }}
                            >
                              {"No, this isn't quite right"}
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              // justifyContent: "space-evenly",
                              gap: 10,
                            }}
                          >
                            {/* <Input
                              placeholder="Send a message..."
                              onPressEnter={() => {
                                if (customPrompt) {
                                  const updatedList = [...questionAnswerListV2];
                                  updatedList[
                                    index
                                  ].finalConfirmationData.confirmed = true;
                                  setQuestionAnswerListV2(updatedList);
                                  getData(customPrompt);
                                  setcustomPrompt("");
                                }
                              }}
                              value={customPrompt}
                              onChange={(e) => {
                                setcustomPrompt(e.target.value);
                              }}
                              style={{
                                padding: "7px 17px",
                                borderRadius: "100px",
                                fontSize: "12px",
                                fontFamily: "Poppins, sans-serif",
                                width: "65%",
                              }}
                            /> */}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                {row.type === "query" && (
                  <>
                    <Slider
                      buttons={
                        isChartExist(row)
                          ? [
                              { name: "Chart", value: "1" },
                              { name: "Data", value: "2" },
                              { name: "Query", value: "3" },
                            ]
                          : [
                              { name: "Data", value: "2" },
                              { name: "Query", value: "3" },
                            ]
                      }
                      defaultValue={row.selectedTab}
                      onValueChange={(v: string) => {
                        const updatedList = questionAnswerListV2.map((item) =>
                          item.id === row.id
                            ? { ...item, selectedTab: v }
                            : item
                        );
                        setQuestionAnswerListV2(updatedList);
                      }}
                    />
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {row.selectedTab === "2" && (
                        <>
                          {row.data && row.data.length > 0 && (
                            <Table
                              style={{ flex: 1 }} // Set the table's flex to distribute remaining space
                              columns={Object.keys(row.data[0]).map((v) => ({
                                title: formatString(v),
                                dataIndex: v,
                                ellipsis: true,
                              }))}
                              dataSource={row.data}
                              scroll={{ x: "max-content" }}
                            />
                          )}
                        </>
                      )}
                      {row.selectedTab === "3" && (
                        <>
                          <div style={{ flex: 1, overflow: "auto" }}>
                            {" "}
                            {/* Set flex and overflow for the text section */}
                            Query: <br />
                            {row.query}
                            <br />
                            <br />
                            Explanation: <br />
                            {row.sqlQueryExplanation}
                          </div>
                        </>
                      )}
                    </div>
                    <div style={{ width: "100%" }}>
                      {row.graphFetching ? (
                        <CustomLoader
                          isLoading={row.graphFetching}
                          text="Looking for relevant graph"
                        />
                      ) : (
                        <Chart
                          questionId={row.questionId}
                          graphs={row.graphs}
                          data={row.data}
                          updateQuestionGraph={updateQuestionGraph}
                        />
                      )}
                      <br />
                      <br />
                      {row.summaryFetching ? (
                        <>
                          <CustomLoader
                            isLoading={row.summaryFetching!}
                            text="Generating the summary"
                          />
                        </>
                      ) : (
                        typeof row.answer === "string" && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                row.answer &&
                                row.answer
                                  .replace(/\n/g, "<br />")
                                  .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
                            }}
                          ></div>
                        )
                      )}
                    </div>
                    {row.questionId && console.log(row.graphs)}
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        alignSelf: "flex-end",
                      }}
                    >
                      <Feedback
                        feedback={row.feedback ? row.feedback : {}}
                        questionId={row.questionId ? row.questionId : ""}
                      />
                      <ExportWidget
                        data={{
                          question: row.question ? row.question : "",
                          questionId: row.questionId ? row.questionId : "",
                          data: row.data,
                          graphs: row.graphs,
                          summary: row.answer,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ChatV2;
