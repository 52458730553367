import { Table } from "antd";
import { IExportWidgetProps, IWidgetConfigProps } from "./ExportWidget";
import { formatString } from "./Chart";
import { DisplayChart } from "./DisplayChart";

export const WidgetPreview = (props: {
  data: IExportWidgetProps;
  widgetConfig: IWidgetConfigProps;
  title: string;
}) => {
  const { data, widgetConfig, title } = props;

  const getUi = (name: string) => {
    if (name === "graph") {
      console.log(widgetConfig["graph"]);
      return (
        <DisplayChart graphs={widgetConfig["graph"].chart} data={data.data} />
      );
    } else if (name === "summary") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html:
              data.summary &&
              data.summary
                .replace(/\n/g, "<br />")
                .replace(/\*(.*?)\*/g, "<strong>$1</strong>"),
          }}
        ></div>
      );
    } else if (name === "title") {
      return <h3>{title}</h3>;
    } else if (name === "data") {
      return (
        <Table
          style={{ flex: 1, marginTop: "8px" }} // Set the table's flex to distribute remaining space
          columns={Object.keys(data.data[0]).map((v) => ({
            title: formatString(v),
            dataIndex: v,
          }))}
          dataSource={data.data}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.keys(widgetConfig)
          .filter((key) => widgetConfig[key].enabled === true)
          .sort(
            (keyA, keyB) =>
              widgetConfig[keyA].position! - widgetConfig[keyB].position!
          )
          .map((row) => (
            <>{getUi(row)}</>
          ))}
      </div>
    </>
  );
};
