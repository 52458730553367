import { useKeycloak } from '@react-keycloak/web';

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;
  // const isLoggedIn = true;

  return isLoggedIn ? children : null;
};

export default PrivateRoute;