import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useState } from "react";

interface ICustomInputProps {
  value: string;
  isUpdating: boolean;
  updateValue: (value: string) => void;
}

export const CustomInput = (props: ICustomInputProps) => {
  const { value, isUpdating, updateValue } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [tempInputValue, setTempInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  return (
    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
      {isEditing ? (
        <>
          <Input
            placeholder="Name"
            style={{
              width: "auto",
              fontWeight: "bold",
              fontSize: "21px",
              fontFamily: "Optimo Plain",
              lineHeight: "0",
            }}
            bordered={false}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <Button
            icon={<CheckOutlined />}
            onClick={() => {
              updateValue(inputValue);
              setIsEditing(false);
            }}
          />
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              setInputValue(tempInputValue);
              setIsEditing(false);
            }}
          />
        </>
      ) : (
        <>
          <h2 style={{ margin: 0, padding: "4px 11px" }}>{inputValue}</h2>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditing(true);
              setTempInputValue(inputValue);
            }}
            loading={isUpdating}
          />
        </>
      )}
    </div>
  );
};
