import { Button, Input, notification, Card, Divider } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import postgresqlIcon from "../../assets/connectorsLogo/PostgreSQL.svg";
import mongodbIcon from "../../assets/connectorsLogo/mongodb.svg";
import snowflakeIcon from "../../assets/connectorsLogo/snowflake.svg";
import athenaIcon from "../../assets/connectorsLogo/Athena.svg";
import clickhouseIcon from "../../assets/connectorsLogo/clickhouse.svg";
import gsheetIcon from "../../assets/connectorsLogo/googlesheet.svg";
import csvIcon from "../../assets/connectorsLogo/csv.svg";
import { CheckCircleFilled } from "@ant-design/icons";
import { configToUse } from "../../Keycloak";

export const baseUrl = configToUse.REACT_APP_API_BASE_URL;
export const baseUiUrl = configToUse.REACT_APP_UI_BASE_URL;

interface IFormElement {
  label: string;
  key: string;
  type?: string;
}
interface IFormConfig {
  title: string;
  subTitle: string;
  dataPostUrl?: string;
  formElements: Array<IFormElement>;
}
interface IConnectorItemProps {
  label: string;
  value: string;
  icon: any;
  disabled: boolean;
  formConfig?: IFormConfig;
}

const connectors: Array<IConnectorItemProps> = [
  {
    label: "PostgresSql",
    value: "postgresql",
    icon: postgresqlIcon,
    disabled: false,
    formConfig: {
      title: "PostgreSQL connection details",
      subTitle:
        "Add your account details and credentials to retrieve the list of tables",
      dataPostUrl: `${baseUrl}/connection/new/`,
      formElements: [
        {
          label: "Connection Name",
          key: "connectionName",
        },
        {
          label: "Host",
          key: "host",
        },
        {
          label: "Port",
          key: "port",
        },
        {
          label: "User",
          key: "user",
        },
        {
          label: "Password",
          key: "password",
          type: "password",
        },
        {
          label: "Database",
          key: "database",
        },
      ],
    },
  },
  {
    label: "Live Google Sheet",
    value: "gsheet",
    icon: gsheetIcon,
    disabled: false,
    formConfig: {
      title: "Google Sheet connection details",
      subTitle: "Add your google sheet details to retrieve the list of tables",
      dataPostUrl: `${baseUrl}/connection/new/gsheet/`,
      formElements: [
        {
          label: "Connection Name",
          key: "connectionName",
        },
        {
          label: "Database",
          key: "dbname",
        },
        {
          label: "Sheet Url",
          key: "sheetUrl",
        },
        {
          label: "Sub-Sheet Name",
          key: "subSheetName",
        },
        {
          label: "Header Row Number",
          key: "columnRowNumber",
          type: "number",
        },
      ],
    },
  },
  {
    label: "MongoDB",
    value: "mongodb",
    icon: mongodbIcon,
    disabled: true,
  },
  {
    label: "Snowflake",
    value: "snowflake",
    icon: snowflakeIcon,
    disabled: true,
  },
  {
    label: "Amazon Athena",
    value: "athena",
    icon: athenaIcon,
    disabled: true,
  },
  {
    label: "Clickhouse",
    value: "clickhouse",
    icon: clickhouseIcon,
    disabled: true,
  },
  {
    label: "Upload CSV",
    value: "csv",
    icon: csvIcon,
    disabled: true,
  },
];

interface IConnectionConfigProps {
  [key: string]: string;
}

type NotificationType = "success" | "info" | "warning" | "error";

export const NewConnection = () => {
  const navigate = useNavigate();
  const [connectionConfig, setConnectionConfig] =
    useState<IConnectionConfigProps>({});
  const [isNewConnectionLoading, setIsNewConnectionLoading] = useState(false);
  // const [connector, setconnector] = useState<string>("postgresql");
  const [selectedConnector, setSelectedConnector] =
    useState<IConnectorItemProps>(connectors[0]);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type: NotificationType,
    description: string
  ) => {
    api[type]({
      message: "Error",
      description: description,
      placement: "bottomRight",
    });
  };

  const updateConnectionConfig = (key: string, value: string) => {
    setConnectionConfig({ ...connectionConfig, [key]: value });
  };

  const newConnection = () => {
    setIsNewConnectionLoading(true);
    axios
      .post(selectedConnector.formConfig?.dataPostUrl!, connectionConfig)
      .then(function (response) {
        console.log(response);
        setIsNewConnectionLoading(false);
        navigate("/connection/" + response.data.id, {
          state: {
            name: response.data.name,
            db_config: response.data.db_config,
            gsheet_config: response.data.gsheet_configs,
          },
        });
      })
      .catch(function (error) {
        console.log(error.response.data.error);
        openNotificationWithIcon("error", error.response.data.error);
        setIsNewConnectionLoading(false);
      });
  };

  const connectorStyle: React.CSSProperties = {
    width: "200px",
    height: "150px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    position: "relative",
    backgroundColor: "rgb(243, 246, 252)",
    borderRadius: "2px",
    cursor: "pointer",
  };
  const selectedConnectorStyle: React.CSSProperties = {
    ...connectorStyle,
    border: "2px solid #1677FF",
  };
  const disabledConnectorStyle: React.CSSProperties = {
    ...connectorStyle,
    pointerEvents: "none",
    opacity: "0.6",
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          gap: 10,
          height: "100%",
        }}
      >
        <div style={{ flex: "3" }}>
          <h2 style={{ margin: 0 }}>Choose your data source</h2>
          <span style={{ color: "#707784", marginTop: "10px" }}>
            Select the data source to get started
          </span>
          <br />
          <br />
          <Card
            style={{
              gap: "1px",
              display: "flex",
              flexWrap: "wrap",
              margin: "1px",
              boxShadow: "none",
            }}
            bordered={false}
          >
            {connectors.map((connector, index) => (
              <Card.Grid
                key={index}
                style={
                  connector.disabled
                    ? disabledConnectorStyle
                    : selectedConnector.value === connector.value
                    ? selectedConnectorStyle
                    : connectorStyle
                }
                onClick={() => setSelectedConnector(connector)}
              >
                <img
                  src={connector.icon}
                  alt={connector.label}
                  height={"48px"}
                />
                <span
                  style={{
                    fontSize: "1rem",
                    marginTop: "0.5rem",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {connector.label}
                </span>
                {selectedConnector.value === connector.value && (
                  <CheckCircleFilled
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      color: "#1677FF",
                    }}
                  />
                )}
              </Card.Grid>
            ))}
          </Card>
        </div>
        <Divider style={{ height: "100%" }} type="vertical" />
        <div style={{ flex: "2" }}>
          <>
            <h2 style={{ margin: "0" }}>
              {selectedConnector.formConfig?.title}
            </h2>
            <span style={{ color: "#707784", marginTop: "10px" }}>
              {selectedConnector.formConfig?.subTitle}
            </span>
            <br />
            <br />
            {selectedConnector?.formConfig?.formElements.map((elm) => (
              <>
                <p style={{ marginBottom: "5px" }}>{elm.label}</p>
                <Input
                  type={elm.type}
                  value={connectionConfig[elm.key]}
                  onChange={(e) => {
                    updateConnectionConfig(elm.key, e.target.value);
                  }}
                />
              </>
            ))}
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <Button
                disabled={
                  selectedConnector.formConfig!.formElements.filter(
                    (row) => !connectionConfig[row.key]
                  ).length > 0
                }
                type="primary"
                style={{ marginLeft: "auto" }}
                onClick={newConnection}
                loading={isNewConnectionLoading}
              >
                Connect
              </Button>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
