import React, { useState, useEffect, useMemo } from "react";
import { Bar, Column, Line, Pie } from "@ant-design/plots";
import { Slider } from "./Slider";
import {
  BarChartOutlined,
  CheckOutlined,
  EditOutlined,
  LineChartOutlined,
  PieChartOutlined,
  PlusOutlined,
  SendOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Divider, Input, Switch, notification } from "antd";
import { Select, Space } from "antd";
import { baseUrl } from "../../database/NewConnection";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

export const formatString = (word) => {
  try {
    return word
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } catch (error) {
    return word;
  }
};

export const Chart = (props) => {
  const { graphs, data, questionId, updateQuestionGraph } = props;
  const [selectedChart, setSelectedChart] = useState(0);
  const [graphList, setGraphList] = useState(graphs ? graphs : []);
  const [isGraphListUpdating, setIsGraphListUpdating] = useState(false);
  const [isNewGraphLoading, setIsNewGraphLoading] = useState(false);
  const [newGraphPrompt, setNewGraphPrompt] = useState("");
  const [isNew, setIsNew] = useState(false);

  const { keycloak } = useKeycloak();

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, description) => {
    api[type]({
      message: "Error",
      description: description,
      placement: "bottomRight",
    });
  };
  useEffect(() => {
    console.log(graphs);
  }, []);
  useEffect(() => {
    // Check if both graphList and selectedChart are defined and not in an initial state
    if (graphList.length > 0 && selectedChart !== null) {
      if (isNewGraphLoading) postNewGraph();
    }
  }, [graphList, selectedChart]);

  const newGraph = () => {
    setIsNewGraphLoading(true);
    var tmpGraphList = [...graphList];
    var length_to_be_sliced = 10;
    if (data.length < length_to_be_sliced) {
      length_to_be_sliced = data.length;
    }
    axios
      .post(
        "https://prod.api.getcrux.ai/dev-ops-lab/v1/workflow-api-config/2c293ab3-3c73-4b61-b325-1017204a9f09/question/new/",
        {
          name:
            keycloak.idTokenParsed?.given_name +
            " " +
            keycloak.idTokenParsed?.family_name,
          email: keycloak.idTokenParsed?.email,
          input: `Question: \n${newGraphPrompt}\n\n data:\n${JSON.stringify(
            data.slice(0, length_to_be_sliced)
          )}\n Ensure that the data in the yField is in a decimal format.`,
        }
      )
      .then(function (response) {
        var json_response = JSON.parse(
          response.data.workflow_response["out-0"]
        );
        // Change chart_config to config
        json_response.config = json_response.chart_config;

        // Remove chart_config property
        delete json_response.chart_config;

        console.log(json_response);
        if (json_response["graph_name"]) {
          tmpGraphList.push({
            is_editing: false,
            ...json_response,
          });
          setGraphList(tmpGraphList);
          setSelectedChart(graphList.length);
          updateQuestionGraph(questionId, tmpGraphList);
          axios
            .post(`${baseUrl}/chat/v3/question/${questionId}/update-chart/`, {
              graph_config: tmpGraphList.map((row) =>
                JSON.stringify({ ...row, is_editing: false })
              ),
            })
            .then(function (response) {
              setIsNew(false);
            })
            .catch((e) => {
              setIsNew(false);
            });
        } else {
          openNotificationWithIcon(
            "error",
            "Unfortunately, we couldn't generate a suitable graph for the provided input."
          );
        }
        setNewGraphPrompt("");
        setIsNewGraphLoading(false);
      })
      .catch((err) => {
        setIsNewGraphLoading(false);
      });
  };

  const postNewGraph = () => {
    axios
      .post(`${baseUrl}/chat/v3/question/${questionId}/update-chart/`, {
        graph_config: graphList.map((row) =>
          JSON.stringify({ ...row, is_editing: false })
        ),
      })
      .then(function (response) {
        setIsNew(false);
      })
      .catch((e) => {
        setIsNew(false);
      });
  };

  const updateGraphList = () => {
    setIsGraphListUpdating(true);
    axios
      .post(`${baseUrl}/chat/v3/question/${questionId}/update-chart/`, {
        graph_config: graphList.map((row) =>
          JSON.stringify({ ...row, is_editing: false })
        ),
      })
      .then(function (response) {
        setIsGraphListUpdating(false);
        setIsNew(false);
        updateGraph(graphList[selectedChart], "is_editing", false);
      })
      .catch(function (e) {
        setIsGraphListUpdating(false);
        setIsNewGraphLoading(false);
        setIsNew(false);
      });
  };

  const updateData = (chartFieldDescription) => {
    try {
      const groupedData = {};
      data.forEach((item) => {
        const key = `${item[chartFieldDescription.xField]}-${
          item[chartFieldDescription.seriesField]
        }${
          item[chartFieldDescription.groupField]
            ? "-" + item[chartFieldDescription.groupField]
            : ""
        }`;
        if (!groupedData[key]) {
          groupedData[key] = {
            [chartFieldDescription.xField]: item[chartFieldDescription.xField],
            [chartFieldDescription.seriesField]:
              item[chartFieldDescription.seriesField],
            [chartFieldDescription.yField]: parseFloat(
              item[chartFieldDescription.yField]
            ),
          };
          if (chartFieldDescription.groupField in item) {
            groupedData[key][chartFieldDescription.groupField] =
              item[chartFieldDescription.groupField];
          }
        } else {
          groupedData[key][chartFieldDescription.yField] += parseFloat(
            item[chartFieldDescription.yField]
          );
        }
      });

      const resultArray = Object.values(groupedData);

      if (
        chartFieldDescription.xField === "month" ||
        chartFieldDescription.xField === "month_year"
      ) {
        return resultArray
          .map((row) => {
            return {
              ...row,
              [chartFieldDescription.xField]:
                row[chartFieldDescription.xField] &&
                row[chartFieldDescription.xField].toString(),
              [chartFieldDescription.yField]: parseFloat(
                row[chartFieldDescription.yField]
              ),
            };
          })
          .sort(function (a, b) {
            var date1 = a[chartFieldDescription.xField];
            var date2 = b[chartFieldDescription.xField];
            const monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];

            const [month1, year1] = date1.split(/\s+/);
            const [month2, year2] = date2.split(/\s+/);

            const index1 = monthNames.indexOf(month1);
            const index2 = monthNames.indexOf(month2);
            if (year1 !== year2) {
              return parseFloat(year1) - parseFloat(year2);
            }

            return index1 - index2;
          });
      }
      var k = resultArray
        .map((row) => {
          return {
            ...row,
            [chartFieldDescription.xField]:
              row[chartFieldDescription.xField] &&
              row[chartFieldDescription.xField].toString(),
            [chartFieldDescription.yField]: parseFloat(
              row[chartFieldDescription.yField]
            ),
          };
        })
        .sort((a, b) => {
          try {
            if (
              b[chartFieldDescription.xField] !==
              a[chartFieldDescription.xField]
            ) {
              return (
                a[chartFieldDescription.xField]
                  ? a[chartFieldDescription.xField]
                  : ""
              ).localeCompare(
                b[chartFieldDescription.xField]
                  ? b[chartFieldDescription.xField]
                  : ""
              );
            }
            return (
              a[chartFieldDescription.seriesField]
                ? a[chartFieldDescription.seriesField]
                : ""
            ).localeCompare(
              b[chartFieldDescription.seriesField]
                ? b[chartFieldDescription.seriesField]
                : ""
            );
          } catch (error) {
            return "";
          }
        });
      return k;
    } catch (error) {
      return [];
    }
  };

  const getChart = (graph) => {
    try {
      const chartSelection = graph.graph_name;
      const chartFieldDescription = graph.config;
      if (chartSelection === "MultiLine") {
        const config = {
          data: updateData(chartFieldDescription),
          xField: chartFieldDescription.xField
            ? chartFieldDescription.xField
            : NaN,
          yField: chartFieldDescription.yField
            ? chartFieldDescription.yField
            : NaN,
          isGroup: true,
          isStack: true,
          seriesField: chartFieldDescription.seriesField
            ? chartFieldDescription.seriesField
            : NaN,
          groupField:
            chartFieldDescription.groupField &&
            chartFieldDescription.groupField !== ""
              ? chartFieldDescription.groupField
              : NaN,
          yAxis: {
            title: {
              text: formatString(chartFieldDescription.yField) + " →",
              style: {
                fontSize: 16,
              },
            },
            // type: 'time',
            tickCount: 5,
          },
          xAxis: {
            title: {
              text: formatString(chartFieldDescription.xField) + " →",
              style: {
                fontSize: 16,
              },
            },
            // type: 'time',
            tickCount: 5,
          },
          point: {
            shape: ({ category }) => {
              return "circle";
            },
          },
        };
        return <Line {...config} />;
      }
      if (
        chartSelection === "GroupedBar" &&
        chartFieldDescription.xField &&
        chartFieldDescription.yField
      ) {
        const config = {
          data: updateData(chartFieldDescription),
          xField:
            chartFieldDescription.xField && chartFieldDescription.xField !== ""
              ? chartFieldDescription.xField
              : NaN,
          yField:
            chartFieldDescription.yField && chartFieldDescription.yField !== ""
              ? chartFieldDescription.yField
              : NaN,
          isGroup: true,
          isStack: true,
          seriesField:
            chartFieldDescription.seriesField &&
            chartFieldDescription.seriesField !== ""
              ? chartFieldDescription.seriesField
              : NaN,
          groupField:
            chartFieldDescription.groupField &&
            chartFieldDescription.groupField !== ""
              ? chartFieldDescription.groupField
              : NaN,
          yAxis: {
            title: {
              text: formatString(chartFieldDescription.yField) + " →",
              style: {
                fontSize: 16,
              },
            },
            // type: 'time',
            tickCount: 5,
          },
          xAxis: {
            title: {
              text: formatString(chartFieldDescription.xField) + " →",
              style: {
                fontSize: 16,
              },
            },
            // type: 'time',
            tickCount: 5,
          },
        };
        return <Column {...config} />;
      }
      if (chartSelection === "Pie") {
        const config = {
          appendPadding: 10,
          data: data.map((row) => {
            return {
              ...row,
              [chartFieldDescription.colorField]:
                row[chartFieldDescription.colorField] &&
                row[chartFieldDescription.colorField].toString(),
              [chartFieldDescription.angleField]: parseFloat(
                row[chartFieldDescription.angleField]
              ),
            };
          }),
          angleField: chartFieldDescription.angleField
            ? chartFieldDescription.angleField
            : NaN,
          colorField: chartFieldDescription.colorField
            ? chartFieldDescription.colorField
            : NaN,
          radius: 0.9,
          label: {
            type: "inner",
            offset: "-30%",
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
              fontSize: 14,
              textAlign: "center",
            },
          },
          interactions: [
            {
              type: "element-active",
            },
          ],
        };
        return <Pie {...config} />;
      }
    } catch (error) {
      return <></>;
    }
  };
  const updateGraph = (graph, key, value) => {
    const tmpGraphList = [...graphList];
    var graphConfig = tmpGraphList.filter((row) => row === graph)[0];
    graphConfig[key] = value;
    setGraphList(tmpGraphList);
    updateQuestionGraph(questionId, tmpGraphList);
  };
  const updateGraphConfig = (graph, key, value) => {
    const tmpGraphList = [...graphList];
    var graphConfig = tmpGraphList.filter((row) => row === graph)[0];
    graphConfig.config[key] = value;
    setGraphList(tmpGraphList);
    updateQuestionGraph(questionId, tmpGraphList);
  };

  const graphFieldMap = {
    MultiLine: ["xField", "yField", "seriesField", "groupField"],
    GroupedBar: [
      "xField",
      "yField",
      "seriesField",
      "groupField",
      // "isGroup",
      // "isStack",
    ],
    Pie: ["angleField", "colorField"],
  };
  const graphIconValueMap = {
    Line: <LineChartOutlined />,
    MultiLine: <LineChartOutlined />,
    Bar: <BarChartOutlined />,
    GroupedBar: <BarChartOutlined />,
    Pie: <PieChartOutlined />,
  };
  const removeChart = () => {
    var tmpGraphList = [...graphList];
    tmpGraphList.pop();
    setSelectedChart(selectedChart - 1);
    setGraphList(tmpGraphList);
    updateQuestionGraph(questionId, tmpGraphList);
    setIsNew(false);
  };
  const addChart = () => {
    var tmpGraphList = [...graphList];
    tmpGraphList.push({
      is_editing: true,
      graph_name: "MultiLine",
      config: {
        xField: "",
        yField: "",
        seriesField: "",
      },
    });
    setSelectedChart(graphList.length);
    setGraphList(tmpGraphList);
    updateQuestionGraph(questionId, tmpGraphList);
    setIsNew(true);
  };
  const chart = useMemo(() => {
    return getChart(graphList[selectedChart]);
  }, [graphList, selectedChart]);
  return (
    <>
      {contextHolder}
      {data && data.length > 0 && (!graphList || graphList.length === 0) && (
        <>
          <Button
            style={{ width: "fit-content", alignSelf: "flex-end" }}
            icon={<PlusOutlined />}
            onClick={addChart}
          >
            Chart
          </Button>
        </>
      )}
      {data &&
        data.length > 0 &&
        graphList &&
        graphList.length > 0 &&
        graphList.filter((row) => {
          return (
            !(
              row.config?.xField &&
              !Object.keys(data[0]).includes(row.config?.xField)
            ) &&
            !(
              row.config?.yField &&
              !Object.keys(data[0]).includes(row.config?.yField)
            ) &&
            !(
              row.config?.seriesField &&
              !Object.keys(data[0]).includes(row.config?.seriesField)
            ) &&
            !(
              row.config?.angleField &&
              !Object.keys(data[0]).includes(row.config?.angleField)
            ) &&
            !(
              row.config?.colorField &&
              !Object.keys(data[0]).includes(row.config?.colorField)
            )
          );
        }).length > 0 &&
        chart && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Slider
                  buttons={graphList
                    .filter((row) => {
                      return (
                        !(
                          row.config?.xField &&
                          !Object.keys(data[0]).includes(row.config?.xField)
                        ) &&
                        !(
                          row.config?.yField &&
                          !Object.keys(data[0]).includes(row.config?.yField)
                        ) &&
                        !(
                          row.config?.seriesField &&
                          !Object.keys(data[0]).includes(
                            row.config?.seriesField
                          )
                        ) &&
                        !(
                          row.config?.angleField &&
                          !Object.keys(data[0]).includes(row.config?.angleField)
                        ) &&
                        !(
                          row.config?.colorField &&
                          !Object.keys(data[0]).includes(row.config?.colorField)
                        )
                      );
                    })
                    .map((graph, index) => {
                      return {
                        name: "",
                        value: index,
                        icon: graphIconValueMap[graph.graph_name],
                      };
                    })}
                  defaultValue={selectedChart}
                  onValueChange={(v) => {
                    setSelectedChart(v);
                  }}
                />
                <Button icon={<PlusOutlined />} onClick={addChart} />
              </div>
              {graphList[selectedChart]?.is_editing ? (
                <>
                  <Button
                    loading={isGraphListUpdating}
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={updateGraphList}
                  />
                  <Button
                    type="default"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      isNew
                        ? removeChart()
                        : updateGraph(
                            graphList[selectedChart],
                            "is_editing",
                            false
                          );
                    }}
                  />
                </>
              ) : (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    updateGraph(graphList[selectedChart], "is_editing", true);
                  }}
                />
              )}
            </div>
            <div style={{ display: "flex", width: "100%", gap: 10 }}>
              <div
                style={{
                  width: graphList[selectedChart]?.is_editing ? "70%" : "99%",
                }}
              >
                {chart}
              </div>
              <Divider
                type="vertical"
                style={{
                  height: "100%",
                  display: graphList[selectedChart]?.is_editing
                    ? "block"
                    : "none",
                }}
              />
              {graphList[selectedChart]?.is_editing && (
                <div style={{ width: "25%" }}>
                  <span>Graph</span>
                  <br />
                  <Select
                    value={graphList[selectedChart]["graph_name"]}
                    onChange={(v) =>
                      updateGraph(graphList[selectedChart], "graph_name", v)
                    }
                    style={{ width: "100%", marginTop: "5px" }}
                    options={[
                      { value: "MultiLine", label: "Line" },
                      { value: "GroupedBar", label: "Bar" },
                      { value: "Pie", label: "Pie" },
                    ]}
                  />
                  <br />
                  <br />
                  {graphFieldMap[graphList[selectedChart]["graph_name"]].map(
                    (row) => (
                      <>
                        {row === "isGroup" || row === "isStack" ? (
                          // <>
                          //   <Switch
                          //     checked={graphList[selectedChart].config[row]}
                          //     onChange={(e) =>
                          //       updateGraphConfig(
                          //         graphList[selectedChart],
                          //         row,
                          //         e
                          //       )
                          //     }
                          //     size="small"
                          //   />
                          //   &nbsp;
                          //   {row}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          // </>
                          <></>
                        ) : (
                          <>
                            <span>{row}</span>
                            <br />
                            <Select
                              value={graphList[selectedChart]["config"][row]}
                              onChange={(v) =>
                                updateGraphConfig(
                                  graphList[selectedChart],
                                  row,
                                  v
                                )
                              }
                              style={{ width: "100%", marginTop: "5px" }}
                              options={
                                data.length > 0
                                  ? Object.keys(data[0])
                                      .map((row) => ({
                                        value: row,
                                        label: row,
                                      }))
                                      .concat({ value: "", label: "None" })
                                  : []
                              }
                            />
                            <br />
                            <br />
                          </>
                        )}
                      </>
                    )
                  )}
                </div>
              )}
            </div>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <label htmlFor="graphInput" style={{ fontSize: "12px" }}>
                {isNewGraphLoading
                  ? "Generating graph..."
                  : "Get your desired Graph:"}
              </label>
              <Input
                placeholder="Send your thought on Graph..."
                onPressEnter={newGraph}
                value={newGraphPrompt}
                onChange={(e) => {
                  setNewGraphPrompt(e.target.value);
                }}
                disabled={isNewGraphLoading}
                style={{
                  padding: "7px 17px",
                  borderRadius: "100px",
                  fontSize: "12px",
                  fontFamily: "Poppins, sans-serif",
                  width: "65%",
                }}
              />
              <Button
                type="primary"
                style={{
                  fontSize: "15px",
                  marginLeft: "15px",
                  cursor: newGraphPrompt ? "pointer" : "not-allowed",
                }}
                onClick={newGraph}
                icon={<SendOutlined />}
                disabled={!newGraphPrompt}
                loading={isNewGraphLoading}
              />
            </div> */}
          </>
        )}
    </>
  );
};
